import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state: {
    logged: false,
    info: {
      data: null,
      error: null,
      loading: false,
      source: null,
    },
    routes: {
      data: null,
      error: null,
      loading: false,
      source: null,
    },
  },
  mutations,
  actions,
  getters,
}
