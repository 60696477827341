import ApiPath from '@/utils/api-path'
import { getLocalStore, unsetLocalStore } from '@/utils/auth'
import request, { postData } from '../request'
import { getRoutes, getRoles } from './subsidiary'
import { MemberItem, UserBaseData, UserRole } from './types'

const applicationId = 2

export function getUserInfo(signal?: AbortSignal | null) {
  const token = getLocalStore('token')
  return request(ApiPath.getUserInfo, {
    method: 'POST',
    signal,
    credentials: 'include',
    skipLogin: true,
    body: JSON.stringify({ applicationId, token }),
  }).then(userInfo => {
    if (!userInfo || !userInfo.paiFuUser) {
      throw new Error('获取用户信息时发生未知错误')
    }
    const {
      nickname,
      paiFuUser: { id: userId, nickname: nickName, username: userName },
      userRoleList,
    } = userInfo
    return {
      nickName: nickName || nickname,
      userId,
      userName,
      roleList: getRoles(userRoleList),
    }
  })
}

export function getUserPermission(signal?: AbortSignal | null) {
  const token = getLocalStore('token')
  return request(ApiPath.getUserPermission, {
    method: 'POST',
    signal,
    credentials: 'include',
    body: JSON.stringify({ applicationId, token }),
  }).then(permission => {
    if (!permission || !Array.isArray(permission.menuList)) {
      throw new Error('您在本系统暂无权限！')
    }
    return getRoutes(permission.menuList)
  })
}

export function logout(signal?: AbortSignal | null) {
  const token = getLocalStore('token')
  unsetLocalStore('token')
  return request(ApiPath.logout, {
    method: 'POST',
    headers: { token },
    signal,
    credentials: 'include',
    body: JSON.stringify({ applicationId, token }),
  })
}

export async function getMemberListInfoByAdmin(
  signal: AbortSignal | null,
  courtId: string | number,
) {
  const res = await request<MemberItem[] | null>(
    ApiPath.getSampleMemberListByAdmin,
    {
      method: 'POST',
      signal,
      body: JSON.stringify({ id: courtId }),
    },
  )
  return res?.[0] || null
}

export function updateMemberListInfo(
  signal: AbortSignal | null,
  memberInfo: MemberItem,
) {
  return request(ApiPath.updateMemberInfoList, {
    method: 'POST',
    signal,
    body: JSON.stringify({ userList: [memberInfo] }),
  })
}

export function getMemberInfoList(nickName: string) {
  return postData<UserBaseData[]>(ApiPath.getMemberInfoList, { nickName })
}

export function getRoleByUser(userId: string) {
  return postData<UserRole[]>(ApiPath.getRoleByUser, { id: userId })
}
