import { Message } from 'element-ui'
import { CancelTokenSource } from 'axios'

type Func<Args extends unknown[] = any[], R = any> = (...args: Args) => {
  promise: Promise<R>
  source: CancelTokenSource
}

export default function <T extends { [key: string]: Func }>(sources: T) {
  const target = {} as T
  ;(Object.entries(sources) as Entries<T>).forEach(([key, value]) => {
    target[key] = ((...rest) => {
      const { promise, source } = value(...rest)
      return {
        promise: promise.catch(reason => {
          if (reason instanceof Error) {
            Message.error(reason.message)
          }
          return Promise.reject(reason)
        }),
        source,
      }
    }) as typeof value
  })
  return target
}

type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]
