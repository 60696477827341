import { render, staticRenderFns } from "./DepartmentTasksList.vue?vue&type=template&id=295fbaa9&scoped=true&"
import script from "./DepartmentTasksList.vue?vue&type=script&lang=ts&"
export * from "./DepartmentTasksList.vue?vue&type=script&lang=ts&"
import style0 from "./DepartmentTasksList.vue?vue&type=style&index=0&id=295fbaa9&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "295fbaa9",
  null
  
)

export default component.exports