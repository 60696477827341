import { render, staticRenderFns } from "./DynamicTag.vue?vue&type=template&id=3f7cd4ba&scoped=true&"
import script from "./DynamicTag.vue?vue&type=script&lang=ts&"
export * from "./DynamicTag.vue?vue&type=script&lang=ts&"
import style0 from "./DynamicTag.vue?vue&type=style&index=0&id=3f7cd4ba&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f7cd4ba",
  null
  
)

export default component.exports