import { post } from '@/utils/request'
import ApiPath from '@/utils/api-path'
import { postData } from '../request'

// 编辑任务时获取任务回写信息
export function getTaskInfo(id: string) {
  return post(ApiPath.getTaskInfo, { id })
}
// 编辑任务时获取执行人列表
export function getViewUserList(taskId: string) {
  return postData(ApiPath.getViewUserInfo, { id: taskId })
}
// 暂存编辑任务信息
export function updateTaskInfo(taskInfoData: any) {
  return post(ApiPath.taskEdit, taskInfoData)
}
// 任务详情
export function queryTaskDetails(id: string) {
  return post(ApiPath.taskView, { id })
}
export function getTaskDetail(id: string) {
  return postData(ApiPath.taskView, { id })
}
// 提交任务
export function saveTask(taskData: any) {
  return post(ApiPath.taskSave, taskData)
}
// 标记任务为已读
export function checkTask(id: string) {
  return post(ApiPath.taskChecked, { id })
}

export function deleteTask(taskId: string, sendMsg: boolean) {
  return postData(ApiPath.taskDelete, { id: taskId, sendMsg })
}

export function retreatTask(taskId: string, backDescribe: string) {
  return postData(ApiPath.taskReturn, { id: taskId, backDescribe })
}

export function getTaskStatusList(courtId = '') {
  return post(ApiPath.taskType, {
    courtId,
  })
}
// 保存法院任务类型
export function saveCourtTask(params: any) {
  return post(ApiPath.courtTaskType, params)
}
// 查询任务类型列表
export function queryTaskType(courtId: string) {
  return post(ApiPath.taskType, { courtId })
}

// 通过courtId查询部门团队成员
export function getSampleMemberList(id?: string) {
  return post(ApiPath.getSampleMemberList, { id })
}
