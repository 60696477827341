import { pickBy } from 'lodash'

export function delay(wait: number) {
  return new Promise(resolve => setTimeout(resolve, wait))
}

export function isPromise(obj: any): obj is Promise<unknown> {
  return (
    !!obj &&
    typeof obj === 'object' &&
    typeof obj.then === 'function' &&
    typeof obj.catch === 'function'
  )
}

export function filterParams(params: object) {
  return pickBy(params, val => (typeof val === 'number' ? true : Boolean(val)))
}

export function isNumber(val: any) {
  return typeof val === 'number' && !isNaN(val)
}

// 格式化金额千分位
export function formatMoney(val: number, num = 2) {
  if (isNumber(val)) {
    const strArr = val.toFixed(num).split('.')
    strArr[0] = (+strArr[0]).toLocaleString()
    strArr[1] = strArr[1] ? strArr[1] : '00'
    return strArr.join('.')
  }
}

// 处理直接添加 % 的值
export function handlePercent(val: any) {
  return typeof val === 'number' ? val + '%' : '0%'
}
