import { reactive } from '@vue/composition-api'
import { Route } from 'vue-router'
import { useComponentInstance } from './VueHelper'

export function useRouter() {
  const instance = useComponentInstance()
  return instance.$router
}

let currentRoute: Route

export function useRoute() {
  if (!currentRoute) {
    const instance = useComponentInstance()
    currentRoute = reactive({ ...instance.$route })
    instance.$router.afterEach(to => Object.assign(currentRoute, to))
  }
  return currentRoute
}
