var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('simple-table',{attrs:{"config":_vm.config,"index":_vm.indexMethod,"data":_vm.data,"loading":_vm.loading},scopedSlots:_vm._u([{key:"reservePrice",fn:function(ref){
var row = ref.row;
return _c('span',{},[_vm._v(_vm._s(_vm._f("currencyFormat")(row.reservePrice)))])}},{key:"billing",fn:function(ref){
var row = ref.row;
return _c('span',{},[_vm._v(_vm._s(_vm._f("currencyFormat")(row.billing)))])}},{key:"statusStr",fn:function(ref){
var row = ref.row;
return _c('span',{},[_vm._v(_vm._s(row.statusStr || '-'))])}},{key:"endTime",fn:function(ref){
var row = ref.row;
return _c('span',{},[_vm._v(_vm._s(row.endTime || '-'))])}},{key:"operation",fn:function(ref){
var row = ref.row;
return [(row.serviceFee === 1 && row.status !== 0)?_c('div',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showInvoice(row, 'applyView')}}},[_vm._v(" 查看 ")])],1):_c('span',[_vm._v("-")])]}},{key:"reviewFlow",fn:function(ref){
var row = ref.row;
return [(row.serviceFee === 1 && row.status !== 0)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showInvoice(row, 'flow')}}},[_vm._v(" 查看 ")]):_c('span',[_vm._v("-")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }