import { post } from '@/utils/request'
import ApiPath from '@/utils/api-path'

// 新增关联关系
export function addRelationship(param: any) {
  return post(ApiPath.addRelation, param)
}

// 设置或修改时限
export function updataTimeLimit(timeData: any) {
  return post(ApiPath.saveOrUpdateRelation, timeData)
}

// 更新绑定规则
export function upDateBindRule(param: any) {
  return post(ApiPath.saveOrUpdateRelation, param)
}

// 查询法院BD
export function queryCourtBD(nickName: string) {
  return post(ApiPath.selUserByNickName, { nickName })
}

// 根据地区查询法院
export function queryCourtByRegion(param: any) {
  return post(ApiPath.selClient, param)
}

// 更换法服BD
export function updateCourtBD(param: any) {
  return post(ApiPath.updRelation, param)
}
