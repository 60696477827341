import moment from 'moment'
import ApiPath from '@/utils/api-path'
import { postData } from '../request'
import { DistributionStatus } from '../paid-commission'

export function convertAuditDistributionValue(
  params: AuditDistributionListParams,
) {
  const { archiveStartTime: startTime, archiveEndTime: endTime } = params
  return {
    ...params,
    regionId: getLastItem(params.regionIds),
    regionIds: undefined,
    archiveStartTime: startTime && moment(startTime).format('YYYY-MM-DD'),
    archiveEndTime: endTime && moment(endTime).format('YYYY-MM-DD'),
  }
}

// 司辅提成管理
export async function getAuditDistributionList(
  params: AuditDistributionListParams,
  pages: CommonPages,
) {
  const res = await postData<CommonResult>(ApiPath.getAuditDistributionList, {
    ...convertAuditDistributionValue(params),
    pageNum: pages.currentPage,
    pageRow: pages.pageSize,
  })
  const list = res.list as unknown[] as AuditDistributionItemData[]
  return { list, total: res.totalCount }
}

export interface AuditDistributionListParams {
  regionIds?: string[] // 法院所在地
  courtName?: string // 法院名称
  caseNum?: string // 案件号
  targetName?: string // 标的物名称
  targetNo?: string // 标的物编号
  distributionStatus?: DistributionStatus // 状态
  archiveStartTime?: Date | null
  archiveEndTime?: Date | null
}

export interface AuditDistributionItemData {
  distributionNo: number | null // 提成申请
  courtId: number
  targetId: number
  invoiceId: number
  entryId: number
  courtName: string
  caseNum: string
  targetNo: string
  targetName: string
  // 服务收入
  realAmount: number // 可提成金额
  distributionAmount: number // 税后可提
  manageAmount: number // 管理提成
  teamAmount: number // 团队分配提成
  crossAmount: number // 异地协助
  // 城市奖金
  distributionStatus: DistributionStatus // 状态
  distributionStatusDesc: string // 节点状态
  auditOperation: 0 | 1 | 2 | 3
}

export function auditDistribution(params: AuditDistributionParams) {
  return postData(ApiPath.auditAndArchivedDistribution, params)
}

interface AuditDistributionParams {
  entryId: number
  distributionStatus: DistributionStatus
  remark: string
}

interface CommonPages {
  currentPage: number
  pageSize: number
}

interface CommonResult {
  [index: string]: unknown
  list: Record<string, unknown>[]
  totalCount: number
}

function getLastItem<T>(arr?: T[] | null) {
  return Array.isArray(arr) && arr.length !== 0
    ? arr[arr.length - 1]
    : undefined
}
