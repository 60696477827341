import moment from 'moment'
import ApiPath from '@/utils/api-path'
import { getData, postData } from '../request'

// 查询签约公司列表
export async function getFinanceCompanyList(
  params: FinanceCompanyListParams,
  pages: CommonPages,
) {
  const { createTimeStart: startTime, createTimeEnd: endTime } = params
  const res = await postData<CommonResult>(ApiPath.financeOrgList, {
    ...params,
    createTimeStart: startTime && moment(startTime).format('YYYY-MM-DD'),
    createTimeEnd: endTime && moment(endTime).format('YYYY-MM-DD'),
    pageNum: pages.currentPage,
    pageRow: pages.pageSize,
  })
  const list = res.list as unknown[] as FinanceCompanyItem[]
  return { list, total: res.totalCount }
}

export interface FinanceCompanyListParams {
  orgName?: string
  status?: 0 | 1 | null // 状态
  updateUserName?: string
  createTimeStart?: Date | null
  createTimeEnd?: Date | null
}

export interface FinanceCompanyItem {
  id: number
  createTime: string // 创建时间
  orgName: string // 公司名称
  orgCode: string // 公司编码
  rate: number | null // 税率
  status: 0 | 1 // 状态
  updateUserName: string // 操作人
  updateTime: string // 更新时间
}

export async function getFinanceCompanyData(financeId: number) {
  const res = await getData<FinanceCompanyData>(
    ApiPath.getFinanceCompany(financeId),
  )
  return {
    ...res,
    id: financeId,
  }
}

interface FinanceCompanyData {
  id: number
  orgCode: string
  orgName: string
  rate: number | null // 公司税率
  banks: ({ id: number } & FinanceCompanyBankItem)[]
  types: ({ id: number } & FinanceCompanyTypeItem)[]
}

interface FinanceCompanyBankItem {
  bankCode: string
  bankName: string
}

interface FinanceCompanyTypeItem {
  typeCode: string
  typeName: string
}

export interface FinanceCompanyFormData {
  id: number | null
  orgCode: string
  orgName: string
  rate: string // 公司税率
  banks: ({ uid: number; id: number | null } & FinanceCompanyBankItem)[]
  types: ({ uid: number; id: number | null } & FinanceCompanyTypeItem)[]
}

export function postFinanceCompanyData(data: PostFinanceCompanyData) {
  const url = data.id ? ApiPath.editFinanceCompany : ApiPath.addFinanceCompany
  return postData(url, data)
}

export interface PostFinanceCompanyData {
  id: number | null
  orgCode: string
  orgName: string
  rate: number
  banks: ({ id: number | null } & FinanceCompanyBankItem)[]
  types: ({ id: number | null } & FinanceCompanyTypeItem)[]
}

export function changeFinanceCompanyStatus(distributionId: number) {
  return getData(ApiPath.changeFinanceCompanyStatus(distributionId))
}

interface CommonPages {
  currentPage: number
  pageSize: number
}

interface CommonResult {
  list: Record<string, unknown>[]
  totalCount: number
}
