import ApiPath from '@/utils/api-path'
import request, { postData } from '../request'

type SubDepartmentData = Array<{ filterId?: number; id: number; name: string }>

type StaffOfDepartmentData = Array<{
  id: string
  crmUserId: string
  userName: string
}> | null

type CourtInDepartment = Array<{
  id: string
  userId: string
  name: string
}> | null

type DistrictList = Array<{ id: string; regionName: string }> | null

type CourtInDistrict = Array<{
  customerId: string
  customerName: string
  customerNo: string
}> | null

export function getSubDepartment(id: string | number) {
  return postData<SubDepartmentData>(ApiPath.getSubDepartment, { id })
}

export function getStaffOfDepartment(
  signal: AbortSignal | null,
  params: {
    region?: string | number
    userName: string
    pageType?: 'review' | null
  },
) {
  const { region, userName, pageType } = params
  return request<StaffOfDepartmentData>(ApiPath.getStaffOfDepartment, {
    method: 'POST',
    signal,
    body: JSON.stringify({
      departmentId: region,
      name: userName,
      pageType: pageType ? pageType : undefined,
    }),
  })
}

export function getDepartmentCourts(
  signal: AbortSignal | null,
  params: {
    region?: string | number
    courtName: string
    pageType?: 'review' | null
  },
) {
  const { region, courtName, pageType } = params
  return request<CourtInDepartment>(ApiPath.getCourtInDepartment, {
    method: 'POST',
    signal,
    body: JSON.stringify({
      departmentId: region,
      name: courtName,
      pageType: pageType ? pageType : undefined,
    }),
  })
}

export function getDistrictList(
  signal: AbortSignal | null,
  id: string | number,
) {
  return request<DistrictList>(ApiPath.getDistrictList, {
    method: 'POST',
    signal,
    body: JSON.stringify({ id }),
  })
}

export function getCourtInDistrict(
  signal: AbortSignal | null,
  region?: string[],
) {
  return request<CourtInDistrict>(ApiPath.getCourtInDistrict, {
    method: 'POST',
    signal,
    body: JSON.stringify({
      regionId:
        Array.isArray(region) && region.length !== 0
          ? region[region.length - 1]
          : undefined,
      pageNum: '9999',
    }),
  })
}

export function getCrmCourt(signal: AbortSignal | null, region?: string[]) {
  return request<CourtInDistrict>(ApiPath.getCrmCourt, {
    method: 'POST',
    signal,
    body: JSON.stringify({
      regionId:
        Array.isArray(region) && region.length !== 0
          ? region[region.length - 1]
          : undefined,
      pageNum: '9999',
    }),
  })
}

export function getServiceCourt(signal: AbortSignal | null, region?: string[]) {
  return request<CourtInDistrict>(ApiPath.getServiceCourt, {
    method: 'POST',
    signal,
    body: JSON.stringify({
      regionId:
        Array.isArray(region) && region.length !== 0
          ? region[region.length - 1]
          : undefined,
      pageNum: '9999',
    }),
  })
}
