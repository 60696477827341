import { Cancel, CancelTokenSource } from 'axios'

export interface State {
  [propName: string]: any
}

export interface SourcePayload {
  source: CancelTokenSource
}

export interface DataPayload {
  data: any
}

export interface ErrorPayload {
  error: Error | Cancel
}

export interface AsyncData<T = any> {
  data: T
  error: Error | Cancel | null
  loading: boolean
  source: CancelTokenSource | null
}

export const toRequest = (state: AsyncData, source: CancelTokenSource) => {
  state.error = null
  state.loading = true
  state.source = source
}

export const toSuccess = (state: AsyncData, data: any) => {
  state.data = data
  state.loading = false
  state.source = null
}

export const toFailure = (state: AsyncData, error: Error | Cancel) => {
  state.error = error
  state.loading = false
  state.source = null
}
