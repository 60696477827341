import { ref } from '@vue/composition-api'
import { Authority, UserAuthority } from './types'

const currentRef = ref<Record<string, string | string[]>>({})

/**
 * 通用权限检查方法
 * Common check permissions method
 * @param { 权限判定 Permission judgment type: string | string[] | Function } authority
 * @param { 你的权限 Your permission description type: string } currentAuthority
 */
const hasPermission = (
  authority: UserAuthority,
  currentAuthority: string | string[],
) => {
  // 没有判定权限 默认查看所有
  if (!authority) {
    return true
  }

  // 组件权限是数组
  if (Array.isArray(authority)) {
    if (Array.isArray(currentAuthority)) {
      return currentAuthority.some(current => authority.includes(current))
    } else {
      return authority.includes(currentAuthority)
    }
  }

  // string 处理
  if (typeof authority === 'string') {
    if (Array.isArray(currentAuthority)) {
      return currentAuthority.some(current => authority === current)
    } else {
      return authority === currentAuthority
    }
  }

  // function 处理
  if (typeof authority === 'function') {
    return authority(currentAuthority)
  }
  throw new Error('unsupported parameters')
}

export function AuthorizedPass(
  authority: UserAuthority,
  authorityType = 'router',
) {
  const currentAuthority = currentRef.value[authorityType]
  return hasPermission(
    authority,
    currentAuthority === undefined ? 'NULL' : currentAuthority,
  )
}

function setAuthority(authority: string | string[], authorityType = 'router') {
  currentRef.value = {
    ...currentRef.value,
    [authorityType]: authority,
  }
}

export function setCurrentAuthority(
  current: Authority | (() => string | string[]),
  type?: string,
) {
  if (current) {
    if (typeof current === 'function') {
      setAuthority(current(), type)
    } else if (typeof current === 'string' || Array.isArray(current)) {
      setAuthority(current, type)
    }
  } else {
    setAuthority('NULL', type)
  }
}
