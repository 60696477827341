import { render, staticRenderFns } from "./TaskCreate.vue?vue&type=template&id=36ab097a&"
import script from "./TaskCreate.vue?vue&type=script&lang=ts&"
export * from "./TaskCreate.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports