import { render, staticRenderFns } from "./CompanyEdit.vue?vue&type=template&id=75aa6ab9&scoped=true&"
import script from "./CompanyEdit.vue?vue&type=script&lang=ts&"
export * from "./CompanyEdit.vue?vue&type=script&lang=ts&"
import style0 from "./CompanyEdit.vue?vue&type=style&index=0&id=75aa6ab9&lang=scss&scoped=true&"
import style1 from "./CompanyEdit.vue?vue&type=style&index=1&module=true&lang=less&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "75aa6ab9",
  null
  
)

export default component.exports