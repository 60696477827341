export default function scrollMainViewTop() {
  const scrollView = document.getElementById('paifuMainView')
  if (scrollView && scrollView.querySelector) {
    const wrap = scrollView.querySelector('.el-scrollbar__wrap')
    if (wrap && wrap.scrollTop >= 0) {
      setTimeout(() => {
        wrap.scrollTop = 0
      }, 200)
    }
  }
}
