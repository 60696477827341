import { State, mutation as mInvoice, action as aInvoice } from './types'
import InvoiceService from '@/services/invoice'
import { ActionTree } from 'vuex'

const actionTree: ActionTree<State, State> = {
  [aInvoice.condition.modify]({ commit, dispatch }, payload) {
    commit(mInvoice.condition.modify, payload)
    return dispatch(aInvoice.dataList.fetch)
  },
  [aInvoice.pagination.modify]({ commit, dispatch }, payload) {
    commit(mInvoice.pagination.modify, payload)
    return dispatch(aInvoice.dataList.fetch)
  },
  [aInvoice.dataList.fetch]({ commit, state }) {
    const { promise, source } = InvoiceService.getInvoiceList({
      ...state.condition,
      pagination: state.pagination,
    })
    if (state.dataList.source !== null) {
      state.dataList.source.cancel('Abandon')
    }
    commit(mInvoice.dataList.request, { source })
    commit(mInvoice.dataTotal.request, { source })
    return promise.then(
      value => {
        if (state.dataList.source === source) {
          commit(mInvoice.dataList.success, { data: value.list })
          commit(mInvoice.dataTotal.success, { data: value.totalCount })
        }
        return value.list
      },
      reason => {
        if (state.dataList.source === source) {
          commit(mInvoice.dataList.failure, { error: reason })
          commit(mInvoice.dataTotal.failure, { error: reason })
        }
        return Promise.reject(reason)
      },
    )
  },
}

export default actionTree
