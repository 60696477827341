export default {
  // 传真或电话号码
  tel: /(^$|^\d{4,7}-\d{3,5}-\d{7,8})$/,

  // 手机号码，11位
  phone: /^1\d{10}$/,

  // 数字或空字符串
  number: /^(?:[1-9]\d*|0)(?:\.\d+)?$/,
}
