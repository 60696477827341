import mutations from './mutations'
import actions from './actions'
import statement from './statement'
import corpore from './corpore'
import assignment from './assignment'

export default {
  namespaced: true,
  state: {
    condition: {},
    dataList: {
      data: null,
      error: null,
      loading: false,
      source: null,
    },
    dataTotal: {
      data: 0,
      error: null,
      loading: false,
      source: null,
    },
    pagination: {
      pageSize: 15,
      currentPage: 1,
    },
  },
  mutations,
  actions,
  modules: {
    statement,
    corpore,
    assignment,
  },
}
