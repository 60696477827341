import ResizeObserver from 'resize-observer-polyfill'

import { DirectiveOptions } from 'vue'

interface RSDirective {
  observer: ResizeObserver
  callback: (...args: any[]) => any
}

interface ResizeElement extends HTMLElement {
  __rsDirective__: RSDirective
}

function isResizeElement(el: HTMLElement): el is ResizeElement {
  return !!(el as ResizeElement).__rsDirective__
}

const isServer = typeof window === 'undefined'

/**
 * @example
 * <element v-resize="onResize">
 *
 * import resize from 'utils/directives/resize'
 * export default {
 *   directives: { resize },
 *   methods: {
 *     onResize({ width, height }, target) {
 *       // your code
 *     }
 *   }
 * }
 */
const resizeDirective: DirectiveOptions = {
  inserted(el, binding) {
    if (isServer) {
      return
    }
    if (!isResizeElement(el)) {
      const callback = binding.value
      const wrappedCallback: ResizeObserverCallback = ([entry]) => {
        if (typeof callback === 'function') {
          callback(entry.contentRect, entry.target)
        }
      }
      const observer = new ResizeObserver(wrappedCallback)
      observer.observe(el)
      ;(el as ResizeElement).__rsDirective__ = { callback, observer }
    }
  },
  update(el, binding, vNode, oldVNode) {
    if (vNode.elm !== oldVNode.elm) {
      const elm = oldVNode.elm as HTMLElement
      if (isResizeElement(elm)) {
        const observer = elm.__rsDirective__.observer
        observer.disconnect()
        observer.observe(el)
        ;(el as ResizeElement).__rsDirective__ = elm.__rsDirective__
      }
    }
    if (isResizeElement(el) && el.__rsDirective__.callback !== binding.value) {
      el.__rsDirective__.callback = binding.value
    }
  },
  unbind(el) {
    if (isResizeElement(el)) {
      el.__rsDirective__.observer.disconnect()
    }
  },
}

export default resizeDirective
