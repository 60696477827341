import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state: {
    createTargetDialogVisible: false,
    createTaskDialogVisible: false,
    courtUser: [],
    taskTypes: [],
  },
  mutations,
  actions,
}
