import { postData } from '@/services/request'

export async function getConsultTypes() {
  const res = await postData('consult/consultTypes')
  return consultTypeAdaptor(res)
}

export interface CascaderItem {
  value: string
  label: string
  children?: CascaderItem[]
}

function consultTypeAdaptor(list: any) {
  if (Array.isArray(list) && list.length > 0) {
    return list.map(({ id, dicName, childNodes }) => {
      const newItem: CascaderItem = {
        value: id,
        label: dicName,
      }
      const children = consultTypeAdaptor(childNodes)
      if (children.length > 0) {
        newItem.children = children
      }
      return newItem
    })
  }
  return []
}
