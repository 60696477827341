import moment from 'moment'

export default {
  setRegionList(state: any, list: any[]) {
    state.regionList = list.map((el: any) => ({
      name: el.fullName,
      value: el.id,
      level: el.level,
    }))
  },
  setCourtList(state: any, list: any[]) {
    state.courtList = list.map((el: any) => ({
      name: el.customerName,
      value: el.courtId,
    }))
    state.selectId = state.courtList[0] ? state.courtList[0].value : ''
  },
  setSelectId(state: any, val: string) {
    state.selectId = val
  },
  setTargetTradingLoading(state: any, val = true) {
    state.targetTradingLoading = val
  },
  setTargetTradingList(state: any, data: any) {
    state.targetTradingList = [
      { time: '昨日', ...data.yesterday },
      { time: '本周', ...data.thisWeek },
      { time: '本月', ...data.thisMonth },
      { time: '全年', ...data.thisYear },
    ]
    state.targetTradingLoading = false
  },
  setIncomeListLoading(state: any, val = true) {
    state.incomeListLoading = val
  },
  setIsSelectProvince(state: any, val = true) {
    state.isSelectProvince = val
  },
  setIncomeList(state: any, list: any[]) {
    state.incomeList = list
    state.incomeListLoading = false
  },
  setLastSixMonthList(state: any, list: any[]) {
    state.lastSixMonthList = list.map((el: any) => ({
      date: moment(el.month).format('M月'),
      dateTooltip: moment(el.month).format('YYYY年MM月'),
      accountPriceAmount: el.accountPriceAmount / 10000,
    }))
  },
  setServiceAuctionList(state: any, list: any[]) {
    state.serviceAuctionList = list.map((el: any) => ({
      date: moment(el.day).format('MM/DD'),
      dateTooltip: moment(el.day).format('YYYY年MM月DD日'),
      newTargetNum: el.newTargetNum,
      netAuctionNum: el.netAuctionNum,
      turnoverTargetNum: el.turnoverTargetNum,
    }))
    const startDay = !list.length
      ? moment().startOf('month').subtract(1, 'day')
      : list[list.length - 1].day
    const startDayNum = !list.length
      ? 0
      : moment(list[list.length - 1].day).date()
    const num = moment().daysInMonth()
    for (let i = 1; i <= num - startDayNum; i++) {
      const day = moment(startDay).add(i, 'day')
      state.serviceAuctionList.push({
        date: moment(day).format('MM/DD'),
        dateTooltip: moment(day).format('YYYY年MM月DD日'),
        newTargetNum: 0,
        netAuctionNum: 0,
        turnoverTargetNum: 0,
      })
    }
  },
  setTargetNumList(state: any, list: any[]) {
    state.targetNumList = list.map((el: any) => ({
      date: moment(el.month).format('M月'),
      dateTooltip: moment(el.month).format('YYYY年MM月'),
      laipai: el.lpTargetNum,
      zhupai: el.zpTargetNum,
      nengpai: el.npTargetNum,
      zhantang: el.ztTargetNum,
      tonglun: el.tlTargetNum,
    }))
    state.turnoverTargetNumList = list.map((el: any) => ({
      date: moment(el.month).format('M月'),
      dateTooltip: moment(el.month).format('YYYY年MM月'),
      laipai: el.lpTurnoverTargetNum,
      zhupai: el.zpTurnoverTargetNum,
      nengpai: el.npTurnoverTargetNum,
      zhantang: el.ztTurnoverTargetNum,
      tonglun: el.tlTurnoverTargetNum,
    }))
  },
}
