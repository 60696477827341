import { post, get } from '@/utils/request'
import ApiPath from '@/utils/api-path'

export function getCourt(userId?: string) {
  const data: any = {
    pageNum: 1,
    pageRow: 99999,
    type: 2,
  }
  if (userId) {
    data.userId = userId
  }
  return post(ApiPath.getCourtList, data)
}

export function getDistributeTargetInfo(caseId: string) {
  return get(ApiPath.getDistributeTargetInfo(caseId))
}

export function getDistributeRecord(id: string) {
  return get(ApiPath.getDistributeRecord(id))
}

export function addOrUpdateDistributeCaseMoney(params: any) {
  return post(ApiPath.addOrUpdateDistributeCaseMoney, params)
}
