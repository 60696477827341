var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trading"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v(" 标的交易数据 "),_c('icon-tooltip',{attrs:{"content":"各时间段内截至昨日各项指标汇总数据"}})],1),_c('div',{staticClass:"link"},[_c('span',{staticClass:"button",on:{"click":function($event){return _vm.handleView('/workbench/target-serve-list')}}},[_vm._v(" 查看标的服务报表 ")]),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('span',{staticClass:"button",on:{"click":function($event){return _vm.handleView('/workbench/deal-situation-list')}}},[_vm._v(" 查看标的成交报表 ")])],1)]),_c('div',{staticClass:"table-con"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.targetTradingLoading),expression:"targetTradingLoading"}],staticClass:"custom-table",attrs:{"data":_vm.targetTradingList}},[_c('el-table-column',{attrs:{"prop":"time","align":"center","width":"40"}}),_c('el-table-column',{attrs:{"prop":"newTargetNum","align":"right","min-width":"100"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" 同伦收案量 "),_c('icon-tooltip',{attrs:{"content":"拍服系统创建标的数量"}})]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.newTargetNum || 0)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"netAuctionNum","align":"right","min-width":"100"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" 同伦挂网量 "),_c('icon-tooltip',{attrs:{"content":"挂网拍次数量汇总"}})]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.netAuctionNum || 0)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"markPfRate","align":"right","min-width":"100"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" 委托率(%) "),_c('icon-tooltip',{attrs:{"content":"同伦一拍挂网标的量/法院总计一拍挂网标的量"}})]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.markPfRate || 0)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"turnoverTargetNum","align":"right","min-width":"100"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" 成交标的 "),_c('icon-tooltip',{attrs:{"content":"成交标的数量汇总"}})]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.turnoverTargetNum || 0)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"turnoverPriceAmount","align":"right","min-width":"130"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" 成交金额(元) "),_c('icon-tooltip',{attrs:{"content":"成交标的金额汇总"}})]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatMoney")(typeof row.turnoverPriceAmount === 'number' ? row.turnoverPriceAmount : 0))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"turnoverTargetRate","align":"right","min-width":"90"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" 成交率 "),_c('icon-tooltip',{attrs:{"content":"成交标的数量/（成交标的+流拍标的数量）*100%"}})]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("handlePercent")(row.turnoverTargetRate))+" ")]}}])}),_c('template',{slot:"empty"},[_c('div',{staticClass:"no-data"},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/images/no-data.svg")}}),_c('div',{staticClass:"txt"},[_vm._v("暂无数据")])])])],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }