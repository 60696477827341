var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('simple-table',{attrs:{"config":_vm.config,"index":_vm.indexMethod,"data":_vm.data,"loading":_vm.loading},scopedSlots:_vm._u([{key:"reservePrice",fn:function(ref){
var row = ref.row;
return _c('span',{},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(row.reservePrice))+" ")])}},{key:"invoiceTime",fn:function(ref){
var row = ref.row;
return _c('span',{},[_vm._v(" "+_vm._s(row.status === 3 || !row.invoiceTime ? '-' : row.invoiceTime)+" ")])}},{key:"billing",fn:function(ref){
var row = ref.row;
return _c('span',{},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(row.billing))+" ")])}},{key:"applyRemark",fn:function(ref){
var row = ref.row;
return _c('span',{},[_vm._v(" "+_vm._s(row.applyRemark ? row.applyRemark : '-')+" ")])}},{key:"invoiceId",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.invoiceId)+" "),(row.relativeInvoiceId)?_c('div',[_vm._v(" （关联id："+_vm._s(row.relativeInvoiceId)+"） ")]):_vm._e()])]}},{key:"operation",fn:function(ref){
var row = ref.row;
return [(
        _vm.hasView &&
        row.status !== 0 &&
        row.status !== 1 &&
        !(_vm.hasExamInvalid && row.status === 7)
      )?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){_vm.showInvoice(
          row,
          [8, 9].includes(row.status)
            ? 'examInvalidView'
            : [7].includes(row.status)
            ? 'applyInvalidView'
            : [2, 3, 4, 5, 6].includes(row.status)
            ? 'examView'
            : 'applyView'
        )}}},[_vm._v(" 查看 ")]):_vm._e(),(_vm.hasExam && row.status === 1)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showInvoice(row, 'exam')}}},[_vm._v(" 审核 ")]):_vm._e(),(_vm.hasExamInvalid && row.status === 7)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showInvoice(row, 'examInvalid')}}},[_vm._v(" 审核 ")]):_vm._e(),(_vm.hasMakeOut && row.status === 2)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showInvoice(row, 'makeOut')}}},[_vm._v(" 开票 ")]):_vm._e(),(_vm.hasTransfer && row.canAccount)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showInvoice(row, 'transfer')}}},[_vm._v(" 到账 ")]):_vm._e(),(
        !(_vm.hasView && row.status !== 0 && row.status !== 1) &&
        !(_vm.hasExam && row.status === 1) &&
        !(_vm.hasMakeOut && row.status === 2) &&
        !(_vm.hasTransfer && row.status === 4) &&
        !(_vm.hasFile && row.status === 5) &&
        !(_vm.hasExamInvalid && row.status === 7)
      )?_c('span',[_vm._v("-")]):_vm._e()]}},{key:"reviewFlow",fn:function(ref){
      var row = ref.row;
return [(row.status !== 0)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showInvoice(row, 'flow')}}},[_vm._v(" 查看 ")]):_c('span',[_vm._v("-")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }