import { render, staticRenderFns } from "./ChangeRuleName.vue?vue&type=template&id=66b6a097&scoped=true&"
import script from "./ChangeRuleName.vue?vue&type=script&lang=ts&"
export * from "./ChangeRuleName.vue?vue&type=script&lang=ts&"
import style0 from "./ChangeRuleName.vue?vue&type=style&index=0&id=66b6a097&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66b6a097",
  null
  
)

export default component.exports