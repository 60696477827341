import { ref, watch } from '@vue/composition-api'
import { RefAbleElHTMLElement, useResize } from '@/functions/Resize'

export function useDialogSize(
  refAbleEl: RefAbleElHTMLElement,
  getDeviation: () => number,
) {
  const bodySize = useResize(refAbleEl)
  const dialogSize = ref({
    top: 100,
    height: 300 as number | null,
  })
  watch(bodySize, entry => {
    if (!entry) {
      return
    }
    const winHeight = window.innerHeight - getDeviation()
    if (420 > winHeight) {
      dialogSize.value = { top: 0, height: 420 }
      return
    }
    const bodyHeight = entry.target.getBoundingClientRect().height
    if (bodyHeight > winHeight - 100) {
      dialogSize.value = { top: 50, height: winHeight - 100 }
      return
    }
    dialogSize.value = {
      top: Math.min(0.15 * winHeight, winHeight - 50 - bodyHeight),
      height: null,
    }
  })
  return dialogSize
}
