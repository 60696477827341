import moment from 'moment'
import ApiPath from '@/utils/api-path'
import { AuctionPlatformType } from '@/utils/rules'
import { getData, postData } from '../request'

export const cycleOption = [
  { id: 1, name: '首次' },
  { id: 2, name: '第2次' },
  { id: 3, name: '第3次' },
  { id: 4, name: '第4次' },
  { id: 5, name: '第5次' },
  { id: 6, name: '第6次' },
]

export function convertBDListFormValue(params: CourtBDListParams) {
  const { regionIds, courtName, nickName, insiderName, filedName } = params
  return {
    regionId: getLastItem(regionIds),
    courtName: courtName || undefined,
    nickName: nickName || undefined,
    insiderName: insiderName || undefined,
    filedName: filedName || undefined,
  }
}

// 查询法院设置列表
export async function getCourtBDList(
  params: CourtBDListParams,
  pages: CommonPages,
) {
  const res = await postData<CommonResult>(ApiPath.selBDUserList, {
    ...convertBDListFormValue(params),
    pageNum: pages.currentPage,
    pageRow: pages.pageSize,
  })
  const list = res.list as unknown[] as CourtBDItem[]
  return { list, total: res.totalCount }
}

export interface CourtBDListParams {
  regionIds: string[] // 法院所在地
  courtName: string // 法院名称
  nickName: string // 法服BD姓名
  insiderName: string // 内勤
  filedName: string // 外勤
}

export interface CourtBDItem {
  courtId: number
  courtName: string
  userId: number // 团队BD
  ruleId: number | null
  ruleDetail: string
}

// 查询法院设置详情
export async function getCourtBDInfo(courtId: number) {
  const res = await getData<Record<string, unknown>>(
    ApiPath.getBDUserInfo(courtId),
  )
  return {
    ...res,
    startTime: getDate(res.startTime as string | null),
    endTime: getDate(res.endTime as string | null),
    serveDate: getDate(res.serveDate as string | null),
    evacuateDate: getDate(res.evacuateDate as string | null),
    shops: res.shops || [],
    titles: ((res.titles || []) as Record<string, unknown>[]).map<unknown>(
      item => {
        const vatTypes: CourtBDVatType[] = []
        if (item.vatGeneralElectronic) {
          vatTypes.push('GENERAL_ELECTRONIC')
        }
        if (item.vatGeneralPaper) {
          vatTypes.push('GENERAL_PAPER')
        }
        if (item.vatSpecial) {
          vatTypes.push('SPECIAL')
        }
        return { ...item, vatTypes }
      },
    ),
  } as CourtBDInfo
}

export interface CourtBDInfo {
  courtId: number
  courtNo: string
  shortName: string
  // 签约公司
  orgId: number | null
  bankId: number | null
  // 提成规则
  distributionRuleId: number | null
  distributionRule: CourtBDDistributionRule | null
  // 收费规则
  ruleId: number | null
  ruleName: string
  ruleDetail: string
  // 修改时限
  startTime: Date | null
  endTime: Date | null
  // 服务情况
  serveStatus: 'STATION' | 'FLOW' | 'EVACUATE' | null
  serveDate: Date | null
  evacuateDate: Date | null
  // 设置周期
  paymentPeriod: number | null
  finalPaymentPeriod: number | null
  // 网拍信息
  shops: ({ id: number } & CourtBDShopItem)[]
  // 发票信息
  titles: ({ id: number } & CourtBDTitleItem)[]
  // 法院合同
  courtSigns: CourtBDcontractItem[]
}

export interface CourtBDDistributionRule {
  id: number
  distributionRuleName: string
  distributionAllRate: number | null
  taxiRate: number | null
  manageRate: number | null
  caseRate: number | null
  cityReward: 0 | 1
  cityRewardRate: number | null
}

export interface CourtBDShopItem {
  platform: AuctionPlatformType
  shopName: string
  shopUrl: string
}

export interface CourtBDTitleItem {
  titleType: CourtBDTitleType
  title: string // 发票抬头
  taxiNo: string // 税号
  unitAddress: string // 单位地址
  phone: string // 电话号码
  email: string // 电子邮箱
  bankName: string // 开户银行
  bankNo: string // 银行账户
  vatTypes: CourtBDVatType[]
}

export interface CourtBDcontractItem {
  cycle: string // 签约轮次
  time?: Array<string> // 时间
  status?: number
  startTime?: string
  endTime?: string
}

export type CourtBDTitleType = 'PERSONAL' | 'COMPANY'
export type CourtBDVatType = 'GENERAL_ELECTRONIC' | 'GENERAL_PAPER' | 'SPECIAL'

type OptionItem<T extends string> = { value: T; text: string }

export const CourtBDVatTypeOptions: OptionItem<CourtBDVatType>[] = [
  { value: 'GENERAL_ELECTRONIC', text: '增值税普通票(电子票)' },
  { value: 'GENERAL_PAPER', text: '增值税普通票(纸质票)' },
  { value: 'SPECIAL', text: '增值税专用票(纸质票)' },
]

export interface CourtBDFormData {
  courtId: number | null
  courtNo: string
  shortName: string
  // 签约公司
  orgId: number | null
  bankId: number | null
  // 提成规则
  distributionRuleId: number | null
  distributionRule: CourtBDDistributionRule | null
  // 收费规则
  ruleId: number | null
  ruleName: string
  ruleDetail: string
  // 修改时限
  startTime: Date | null
  endTime: Date | null
  // 服务情况
  serveStatus: 'STATION' | 'FLOW' | 'EVACUATE' | null
  serveDate: Date | null
  evacuateDate: Date | null
  // 设置周期
  paymentPeriod: string
  finalPaymentPeriod: string
  // 网拍信息
  shops: ({ uid: number; id: number | null } & CourtBDShopItem)[]
  // 发票信息
  titles: ({ uid: number; id: number | null } & CourtBDTitleItem)[]
  // 法院合同
  courtSigns: CourtBDcontractItem[]
}

export function postCourtBDData(data: PostCourtBDData) {
  return postData(ApiPath.editBDUserInfo, {
    ...data,
    titles: data.titles.map(item => ({
      ...item,
      vatTypes: undefined,
      vatGeneralElectronic: item.vatTypes.includes('GENERAL_ELECTRONIC')
        ? 1
        : 0,
      vatGeneralPaper: item.vatTypes.includes('GENERAL_PAPER') ? 1 : 0,
      vatSpecial: item.vatTypes.includes('SPECIAL') ? 1 : 0,
    })),
  })
}

export interface PostCourtBDData {
  courtId: number
  courtNo: string
  shortName: string
  // 签约公司
  orgId: number
  bankId: number
  // 提成规则
  distributionRuleId: number
  // 收费规则
  ruleId: number | null
  // 收费规则时限
  startTime: Date | null
  endTime: Date | null
  // 服务情况
  serveStatus: 'STATION' | 'FLOW' | 'EVACUATE'
  serveDate: Date
  evacuateDate: Date
  // 设置周期
  paymentPeriod: number
  finalPaymentPeriod: number
  // 网拍信息
  shops: ({ id: number | null } & CourtBDShopItem)[]
  // 发票信息
  titles: ({ id: number | null } & CourtBDTitleItem)[]
  // 法院合同
  courtSigns: CourtBDcontractItem[]
}

// 收费规则列表
export async function getRuleList(
  params: { courtId: number; ruleName: string },
  pages: CommonPages,
) {
  const res = await postData<CommonResult>(ApiPath.ruleList, {
    id: params.courtId,
    ruleName: params.ruleName,
    pageNum: pages.currentPage,
    pageRow: pages.pageSize,
  })
  const list = res.list as unknown[] as RuleItemData[]
  return { list, total: res.totalCount }
}

export interface RuleItemData {
  id: number
  ruleName: string
  ruleDetail: string
}

function getDate(str?: string | null) {
  const date = str ? moment(str, 'YYYY-MM-DD') : null
  return date && date.isValid() ? date.toDate() : null
}

interface CommonPages {
  currentPage: number
  pageSize: number
}

interface CommonResult {
  list: Record<string, unknown>[]
  totalCount: number
}

function getLastItem<T>(arr?: T[] | null) {
  return Array.isArray(arr) && arr.length !== 0
    ? arr[arr.length - 1]
    : undefined
}
