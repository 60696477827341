import { postData } from '../request'
import ApiPath from '@/utils/api-path'

export function getCourtDataList(params: any = {}) {
  return postData(ApiPath.courtDataList, params)
}

export function getPermissionRegionOrCourt() {
  return postData(ApiPath.getPermissionRegionOrCourt)
}

export function getOverviewData(params: any = {}) {
  return postData(ApiPath.getOverviewData, params)
}

export function getPlatFormTargetData(params: any = {}) {
  return postData(ApiPath.getPlatFormTargetData, params)
}
