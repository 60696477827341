export const getQueryParams = (href: string | null | undefined) => {
  const params: { [propName: string]: string } = {}
  if (href === '' || href === null || href === undefined) {
    return params
  } else {
    href.replace(
      /([^(?|#)=&]+)(=([^&]*))?/g,
      ($0, $1, $2, $3) => (params[$1] = $3),
    )
    return params
  }
}

export function setLocalStore(name: string, content: any) {
  localStorage.setItem(name, JSON.stringify(content))
}

export function getLocalStore<T = any>(name: string) {
  let data: T | undefined
  try {
    const content = localStorage.getItem(name)
    if (content !== null) {
      data = JSON.parse(content)
    }
  } catch (_) {
    // ignore
  }
  return data
}

export const unsetLocalStore = (name: string) => localStorage.removeItem(name)

export function skipSSO(logout: boolean, referrer?: string) {
  if (!logout) {
    // 10s内循环登录3次时禁止自动登录
    const token = getLocalStore('token')
    if (token) {
      const ssoInfo = getLocalStore<{ token: string; tamps: number[] }>(
        'sso-login',
      )
      const currentTime = Date.now() / 1000
      if (ssoInfo && ssoInfo.token === token && Array.isArray(ssoInfo.tamps)) {
        ssoInfo.tamps = ssoInfo.tamps.filter(item => item > currentTime - 10)
        if (ssoInfo.tamps.length > 2) {
          logout = true
          unsetLocalStore('sso-login')
        } else {
          ssoInfo.tamps.push(currentTime)
          setLocalStore('sso-login', ssoInfo)
        }
      } else {
        setLocalStore('sso-login', { token, tamps: [currentTime] })
      }
    }
  }
  const baseUrl = `${location.protocol}//${location.host}${location.pathname}`

  const redirectUrl = encodeURIComponent(
    `${baseUrl}#/signed_in?referrer=${encodeURIComponent(
      referrer || '/index',
    )}`,
  )

  window.location.href = `${process.env.VUE_APP_SIGN_IN_URL}?${
    logout ? 'auth_fail=logout&' : ''
  }redirect_url=${redirectUrl}`
}
