import { render, staticRenderFns } from "./SpecialDetail.vue?vue&type=template&id=36e28488&scoped=true&"
import script from "./SpecialDetail.vue?vue&type=script&lang=ts&"
export * from "./SpecialDetail.vue?vue&type=script&lang=ts&"
import style0 from "./SpecialDetail.vue?vue&type=style&index=0&id=36e28488&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36e28488",
  null
  
)

export default component.exports