import { render, staticRenderFns } from "./JudicialCommissionAudit.vue?vue&type=template&id=0457790a&scoped=true&"
import script from "./JudicialCommissionAudit.vue?vue&type=script&lang=ts&"
export * from "./JudicialCommissionAudit.vue?vue&type=script&lang=ts&"
import style0 from "./JudicialCommissionAudit.vue?vue&type=style&index=0&module=true&lang=less&"
import style1 from "./JudicialCommissionAudit.vue?vue&type=style&index=1&id=0457790a&lang=scss&scoped=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "0457790a",
  null
  
)

export default component.exports