import axios from 'axios'
import moment from 'moment'
import ApiPath from '@/utils/api-path'
import request, { postData } from '../request'
import { ApiResult, Pagination } from '../types'
import { FormValue, Target, TargetTask } from './types'
import message from '../message'

const CancelToken = axios.CancelToken

interface SampleListParams extends FormValue {
  pagination: Pagination
}

export function convertFormValue(params: FormValue) {
  const {
    region,
    courtName,
    court,
    targetName,
    customerName,
    customerPhone,
    startTime,
    endTime,
    status,
  } = params
  return {
    regionId:
      Array.isArray(region) && region.length !== 0
        ? region[region.length - 1]
        : undefined,
    courtId: court ? court.customerId : undefined,
    courtName: courtName ? courtName : undefined,
    targetName: targetName ? targetName : undefined,
    customName: customerName ? customerName : undefined,
    customPhone: customerPhone ? customerPhone : undefined,
    startTime: startTime ? moment(startTime).format('YYYY-MM-DD') : undefined,
    endTime: endTime ? moment(endTime).format('YYYY-MM-DD') : undefined,
    status: status === 'present' ? 1 : status === 'nonPresent' ? 2 : -1,
  }
}

// 查询我的带看列表
export async function getMySampleList(params: FormValue, pages: CommonPages) {
  const res = await postData<CommonResult>(ApiPath.getSampleList, {
    ...convertFormValue(params),
    pageNum: pages.currentPage,
    pageRow: pages.pageSize,
  })
  const list = res.list
  return { list, total: res.totalCount }
}

function getRegistrationList({ pagination, ...params }: SampleListParams) {
  const sampleParams: any = convertFormValue(params)
  sampleParams.pageNum = pagination.currentPage
  sampleParams.pageRow = pagination.pageSize
  const source = CancelToken.source()
  const promise = axios.post<ApiResult>(
    ApiPath.getRegistrationList,
    sampleParams,
    { cancelToken: source.token },
  )
  return {
    promise: promise.then(({ data }) => {
      if (data.returnCode !== '100') {
        return Promise.reject(new Error(data.returnMsg))
      } else {
        return data.returnData as any
      }
    }),
    source,
  }
}

function getDepartmentSampleList({ pagination, ...params }: SampleListParams) {
  const sampleParams: any = convertFormValue(params)
  sampleParams.pageNum = pagination.currentPage
  sampleParams.pageRow = pagination.pageSize
  const source = CancelToken.source()
  const promise = axios.post<ApiResult>(
    ApiPath.getDepartmentSampleList,
    sampleParams,
    { cancelToken: source.token },
  )
  return {
    promise: promise.then(({ data }) => {
      if (data.returnCode !== '100') {
        return Promise.reject(new Error(data.returnMsg))
      } else {
        return data.returnData as any
      }
    }),
    source,
  }
}

function getDepartmentRegistrationList({
  pagination,
  ...params
}: SampleListParams) {
  const sampleParams: any = convertFormValue(params)
  sampleParams.pageNum = pagination.currentPage
  sampleParams.pageRow = pagination.pageSize
  const source = CancelToken.source()
  const promise = axios.post<ApiResult>(
    ApiPath.getDepartmentRegistrationList,
    sampleParams,
    { cancelToken: source.token },
  )
  return {
    promise: promise.then(({ data }) => {
      if (data.returnCode !== '100') {
        return Promise.reject(new Error(data.returnMsg))
      } else {
        return data.returnData as any
      }
    }),
    source,
  }
}

export function getCustomerInfo(
  signal: AbortSignal | null,
  { id }: { id: string },
) {
  return request(ApiPath.getCustomerInfo, {
    method: 'POST',
    signal,
    body: JSON.stringify({ id }),
  })
}

export function getTargetInfoList(
  signal: AbortSignal | null,
  { queryValue }: { queryValue: string },
) {
  return request(ApiPath.getTargetList, {
    method: 'POST',
    signal,
    body: JSON.stringify({ queryValue }),
  })
}

function getTargetList({ queryValue }: { queryValue: string }) {
  const source = CancelToken.source()
  const promise = axios.post<ApiResult>(
    ApiPath.getTargetList,
    { queryValue },
    { cancelToken: source.token },
  )
  return {
    promise: promise.then(({ data }) => {
      if (data.returnCode !== '100') {
        return Promise.reject(new Error(data.returnMsg))
      } else {
        return data.returnData as any
      }
    }),
    source,
  }
}

function getTaskListForTarget({ targetId }: { targetId: string | number }) {
  const source = CancelToken.source()
  const promise = axios.get<ApiResult>(ApiPath.getTaskListForTarget(targetId), {
    cancelToken: source.token,
  })
  return {
    promise: promise.then(({ data }) => {
      if (data.returnCode !== '100') {
        return Promise.reject(new Error(data.returnMsg))
      } else {
        if (data.returnData && data.returnData.length > 0) {
          return data.returnData as any
        } else {
          return [] as any
        }
      }
    }),
    source,
  }
}

function getRelatedViewNum({
  targetId,
  taskId,
  auctionId,
}: {
  targetId: string | number
  taskId: string | number
  auctionId: string | number
}) {
  const source = CancelToken.source()
  let url = ''
  if (targetId) {
    url = ApiPath.getTargetViewsNumByTarget(targetId)
  } else {
    if (taskId) {
      url = ApiPath.getTargetViewsNumByTask(taskId)
    } else {
      if (auctionId) {
        url = ApiPath.getAuctionViews(auctionId)
      }
    }
  }
  const promise = axios.get<ApiResult>(url, {
    cancelToken: source.token,
  })
  return {
    promise: promise.then(({ data }) => {
      if (data.returnCode !== '100') {
        return 0
      } else {
        if (data.returnData) {
          return data.returnData as number
        } else {
          return 0
        }
      }
    }),
    source,
  }
}

function putSampleInfo({
  target,
  targetTask,
  customName,
  customPhone,
}: {
  target: Target
  targetTask: TargetTask
  customName: string
  customPhone: string
}) {
  const source = CancelToken.source()
  const promise = axios.post<ApiResult>(
    ApiPath.putSampleInfo,
    {
      targetId: target.id,
      targetName: target.targetName,
      taskId: targetTask.taskId,
      viewTime: targetTask.viewTime,
      customName,
      customPhone,
    },
    { cancelToken: source.token },
  )
  return {
    promise: promise.then(({ data }) => {
      if (data.returnCode !== '100') {
        return Promise.reject(new Error(data.returnMsg))
      } else {
        return data.returnData as any
      }
    }),
    source,
  }
}

function addLookView({
  target,
  targetTask,
  name,
  phone,
  sendMsg,
}: {
  target: Target
  targetTask: TargetTask
  name: string
  phone: string
  sendMsg: boolean
}) {
  const source = CancelToken.source()
  const params: any = {
    targetId: target.id,
    name,
    phone,
    sendMsg,
  }
  if (targetTask && targetTask.taskId) {
    params.taskId = targetTask.taskId
  }
  const promise = axios.post<ApiResult>(ApiPath.addLookView, params, {
    cancelToken: source.token,
  })
  return {
    promise: promise.then(({ data }) => {
      if (data.returnCode !== '100') {
        return Promise.reject(new Error(data.returnMsg))
      } else {
        return data.returnData as any
      }
    }),
    source,
  }
}

function getCustomerTag(targetId: string | number) {
  const source = CancelToken.source()
  const promise = axios.get<ApiResult>(ApiPath.getCustomerTag(targetId), {
    cancelToken: source.token,
  })
  return {
    promise: promise.then(({ data }) => {
      if (data.returnCode !== '100') {
        return Promise.reject(new Error(data.returnMsg))
      } else {
        const result: any = {
          customerId: null,
          tags: [] as any[],
        }
        if (data.returnData) {
          if (
            data.returnData.customerTagList &&
            data.returnData.customerTagList.length > 0
          ) {
            result.tags = data.returnData.customerTagList
          }
          if (data.returnData.customerId) {
            result.customerId = data.returnData.customerId
          }
        }
        return result
      }
    }),
    source,
  }
}

function saveCustomerTag({
  customerId,
  tagList,
}: {
  customerId: number
  tagList: number[]
}) {
  const source = CancelToken.source()
  const promise = axios.post<ApiResult>(
    ApiPath.saveCustomerTag,
    {
      customerId,
      tagList,
    },
    { cancelToken: source.token },
  )
  return {
    promise: promise.then(({ data }) => {
      if (data.returnCode !== '100') {
        return Promise.reject(new Error(data.returnMsg))
      } else {
        return data.returnData as any
      }
    }),
    source,
  }
}

export default message({
  getRegistrationList,
  getDepartmentSampleList,
  getDepartmentRegistrationList,
  getTargetList,
  getTaskListForTarget,
  putSampleInfo,
  addLookView,
  getRelatedViewNum,
  getCustomerTag,
  saveCustomerTag,
})

interface CommonPages {
  currentPage: number
  pageSize: number
}

interface CommonResult {
  list: Record<string, unknown>[]
  totalCount: number
}
