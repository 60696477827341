import { render, staticRenderFns } from "./DistributionList.vue?vue&type=template&id=de424992&scoped=true&"
import script from "./DistributionList.vue?vue&type=script&lang=ts&"
export * from "./DistributionList.vue?vue&type=script&lang=ts&"
import style0 from "./DistributionList.vue?vue&type=style&index=0&id=de424992&lang=less&scoped=true&"
import style1 from "./DistributionList.vue?vue&type=style&index=1&module=true&lang=less&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "de424992",
  null
  
)

export default component.exports