import axios from 'axios'
import moment from 'moment'
import ApiPath from '@/utils/api-path'
import { Pagination, ApiResult } from '../types'
import { getData, postData } from '../request'
import { ApplyFormValue, ExamFormValue } from './types'
import message from '../message'
import { CourtBDTitleItem, CourtBDVatType } from '../team'

const CancelToken = axios.CancelToken

interface InvoiceListParams extends ApplyFormValue, ExamFormValue {
  pagination: Pagination
}

function getInvoiceList({
  region,
  userName,
  courtName,
  caseNum,
  targetNum,
  targetName,
  startTime,
  endTime,
  applyStartTime,
  applyEndTime,
  status,
  billingStart,
  billingEnd,
  serviceTypes,
  invoiceTimeStart,
  invoiceTimeEnd,
  entryAmount,
  entryTimeStart,
  entryTimeEnd,
  pageType,
  pagination,
}: InvoiceListParams) {
  const params = {
    regionId: (region && region[region.length - 1]) || undefined,
    caseNum: caseNum ? caseNum : undefined,
    courtName: courtName ? courtName : undefined,
    targetNum: targetNum ? targetNum : undefined,
    targetName: targetName ? targetName : undefined,
    userName: userName ? userName : undefined,
    departmentId:
      Array.isArray(region) && region.length !== 0
        ? region[region.length - 1].id
        : undefined,
    startTime: startTime ? moment(startTime).format('YYYY-MM-DD') : undefined,
    endTime: endTime ? moment(endTime).format('YYYY-MM-DD') : undefined,
    applyStartTime: applyStartTime
      ? moment(applyStartTime).format('YYYY-MM-DD')
      : undefined,
    applyEndTime: applyEndTime
      ? moment(applyEndTime).format('YYYY-MM-DD')
      : undefined,
    invoiceTimeStart: invoiceTimeStart
      ? moment(invoiceTimeStart).format('YYYY-MM-DD')
      : undefined,
    invoiceTimeEnd: invoiceTimeEnd
      ? moment(invoiceTimeEnd).format('YYYY-MM-DD')
      : undefined,
    entryAmount: entryAmount ? entryAmount : undefined,
    entryTimeStart: entryTimeStart
      ? moment(entryTimeStart).format('YYYY-MM-DD')
      : undefined,
    entryTimeEnd: entryTimeEnd
      ? moment(entryTimeEnd).format('YYYY-MM-DD')
      : undefined,
    invoiceStatus: status !== '' ? status : undefined,
    billingStart: billingStart !== '' ? billingStart : undefined,
    billingEnd: billingEnd !== '' ? billingEnd : undefined,
    serviceFee:
      Array.isArray(serviceTypes) && serviceTypes.length === 1
        ? serviceTypes[0]
        : undefined,
    pageType: pageType ? pageType : undefined,
    pageNum: pagination.currentPage,
    pageRow: pagination.pageSize,
  }
  const source = CancelToken.source()
  const promise = axios.post<ApiResult>(ApiPath.getInvoiceList, params, {
    cancelToken: source.token,
  })
  return {
    promise: promise.then(({ data }) => {
      if (data.returnCode !== '100') {
        return Promise.reject(new Error(data.returnMsg))
      } else {
        return data.returnData as any
      }
    }),
    source,
  }
}

function myInvoiceList({
  userName,
  courtName,
  caseNum,
  targetNum,
  targetName,
  startTime,
  endTime,
  applyStartTime,
  applyEndTime,
  status,
  invoiceTimeStart,
  invoiceTimeEnd,
  entryAmount,
  entryTimeStart,
  entryTimeEnd,
  serviceTypes,
  pageType,
  pagination,
}: InvoiceListParams) {
  const params = {
    caseNum: caseNum ? caseNum : undefined,
    courtName: courtName ? courtName : undefined,
    targetNum: targetNum ? targetNum : undefined,
    targetName: targetName ? targetName : undefined,
    userName: userName ? userName : undefined,
    startTime: startTime ? moment(startTime).format('YYYY-MM-DD') : undefined,
    endTime: endTime ? moment(endTime).format('YYYY-MM-DD') : undefined,
    invoiceStatus: status !== '' ? status : undefined,
    invoiceTimeStart: invoiceTimeStart ? invoiceTimeStart : undefined,
    invoiceTimeEnd: invoiceTimeEnd ? invoiceTimeEnd : undefined,
    entryAmount: entryAmount ? entryAmount : undefined,
    entryTimeStart: entryTimeStart ? entryTimeStart : undefined,
    entryTimeEnd: entryTimeEnd ? entryTimeEnd : undefined,
    applyStartTime: applyStartTime
      ? moment(applyStartTime).format('YYYY-MM-DD')
      : undefined,
    applyEndTime: applyEndTime
      ? moment(applyEndTime).format('YYYY-MM-DD')
      : undefined,
    serviceFee:
      Array.isArray(serviceTypes) && serviceTypes.length === 1
        ? serviceTypes[0]
        : undefined,
    pageType: pageType ? pageType : undefined,
    pageNum: pagination.currentPage,
    pageRow: pagination.pageSize,
  }
  const source = CancelToken.source()
  const promise = axios.post<ApiResult>(ApiPath.myInvoiceList, params, {
    cancelToken: source.token,
  })
  return {
    promise: promise.then(({ data }) => {
      if (data.returnCode !== '100') {
        return Promise.reject(new Error(data.returnMsg))
      } else {
        return data.returnData as any
      }
    }),
    source,
  }
}

function updateServiceTypeById({
  targetId,
  serviceType,
  serviceMoney,
}: {
  targetId: string | number
  serviceType: number
  serviceMoney: string | number
}) {
  const source = CancelToken.source()
  const promise = axios.post<ApiResult>(
    ApiPath.updateTargetById,
    { id: targetId, serviceFee: serviceType, serviceMoney },
    { cancelToken: source.token },
  )
  return {
    promise: promise.then(({ data }) => {
      if (data.returnCode !== '100') {
        return Promise.reject(new Error(data.returnMsg))
      } else {
        return data.returnData as any
      }
    }),
    source,
  }
}

export function getInvoiceOfTarget(data: {
  targetId: string | number
  invoiceTitle: string
}) {
  return postData<InvoiceOfTargetItem[]>(ApiPath.getInvoiceOfTarget, {
    targetId: data.targetId,
    invoiceTitle: data.invoiceTitle,
  })
}

interface InvoiceOfTargetItem {
  invoiceTitle: string
  invoiceNum: string
}

export async function getInvoiceTitleList(targetId: string | number) {
  const res = await getData<Record<string, unknown>[]>(
    ApiPath.getInvoiceTitleList(targetId),
  )
  return res.map(item => {
    const vatTypes: CourtBDVatType[] = []
    if (item.vatGeneralElectronic) {
      vatTypes.push('GENERAL_ELECTRONIC')
    }
    if (item.vatGeneralPaper) {
      vatTypes.push('GENERAL_PAPER')
    }
    if (item.vatSpecial) {
      vatTypes.push('SPECIAL')
    }
    return { ...item, vatTypes } as InvoiceTitleItem
  })
}

export type InvoiceTitleItem = { id: number } & CourtBDTitleItem

function postInvoiceInfo({
  ...newParams
}: {
  [propName: string]: unknown
  targetId: string | number
}) {
  // 收费方式为1的时候清空rate值
  if (newParams.chargingMethod) {
    newParams.rate = ''
  }

  // 抬头类型titleType 1是企业 2是个人
  // 发票类型invoiceType 1是增值税专用发票 2,3是增值税普通发票
  if (newParams.titleType !== 1) {
    newParams.invoiceNum = ''
  }
  if (newParams.titleType !== 1 || newParams.invoiceType !== 1) {
    newParams.phone = ''
    newParams.unitAddress = ''
    newParams.depositBank = ''
    newParams.bankAccount = ''
  }

  const source = CancelToken.source()
  const promise = axios.post<ApiResult>(
    newParams.schedule === 'delayReason'
      ? ApiPath.postInvoiceDelayReason
      : ApiPath.postInvoiceInfo,
    newParams,
    { cancelToken: source.token },
  )
  return {
    promise: promise.then(({ data }) => {
      if (data.returnCode !== '100') {
        return Promise.reject(new Error(data.returnMsg))
      } else {
        return data.returnData as any
      }
    }),
    source,
  }
}

function getInvoiceDetail({
  targetId,
  invoiceId,
  reservePrice,
}: {
  targetId?: string | number
  invoiceId?: string | number
  reservePrice?: string | number
}) {
  const source = CancelToken.source()
  const promise = axios.post<ApiResult>(
    ApiPath.getInvoiceDetail,
    { targetId, id: invoiceId, reservePrice },
    { cancelToken: source.token },
  )
  return {
    promise: promise.then(({ data }) => {
      if (data.returnCode !== '100') {
        return Promise.reject(new Error(data.returnMsg))
      } else {
        return data.returnData as any
      }
    }),
    source,
  }
}

function getOperateLog({ invoiceId }: { invoiceId: string | number }) {
  const source = CancelToken.source()
  const promise = axios.post<ApiResult>(
    ApiPath.getInvoiceOperateLog,
    { id: invoiceId, pageNum: 1, pageRow: 9999 },
    { cancelToken: source.token },
  )
  return {
    promise: promise.then(({ data }) => {
      if (data.returnCode !== '100') {
        return Promise.reject(new Error(data.returnMsg))
      } else {
        return data.returnData as any
      }
    }),
    source,
  }
}

export default message({
  getInvoiceList,
  updateServiceTypeById,
  postInvoiceInfo,
  getInvoiceDetail,
  getOperateLog,
  myInvoiceList,
})
