import { Message, MessageBox, Notification } from 'element-ui'

export function eMsg(errMsg: string) {
  Message.error(errMsg)
  return errMsg
}
export function sMsg(msg: string) {
  Message.success(msg)
  return msg
}

export function eNotify(errMsg: string, title = '错误') {
  Notification.error({ title, message: errMsg })
  return errMsg
}
export function sNotify(msg: string, title = '成功') {
  Notification.success({ title, message: msg })
  return msg
}

export function eAlert(errMsg: string, title = '提示') {
  MessageBox.alert(errMsg, title, {
    type: 'error',
  })
  return errMsg
}
export function sAlert(msg: string, title = '提示') {
  MessageBox.alert(msg, title, {
    type: 'success',
  })
  return msg
}
