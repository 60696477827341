import { Commit } from 'vuex'
import {
  getCourt,
  getTeamBD,
  getJudge,
  getCase,
  updateCase,
  createJudge,
  deleteCase,
} from '@/services/cases'
import { eMsg } from '@/utils/feedback'
import { getTargetList } from '@/services/target'

export default {
  async queryCourt(context: { commit: Commit }, param: any) {
    try {
      const courtList = await getCourt(param)
      context.commit('setCourtList', courtList)
      return 'ok'
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
  async queryTeamBD(context: { commit: Commit }) {
    try {
      const courtList = await getTeamBD()
      context.commit('setTeamBDList', courtList)
      return 'ok'
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },

  async queryTargetList(context: { commit: Commit }, param: any) {
    try {
      context.commit('changeSimpleTargetListDialogVisible', true)
      context.commit('simpleTargetListStartLoad')
      const targetList: any = await getTargetList(param)
      context.commit('simpleTargetListFinishLoad', targetList.list)
      return 'ok'
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },

  async queryJudge(context: { commit: Commit }, param: any) {
    try {
      let courtList = await getJudge(param)
      if (!courtList) {
        courtList = []
      }
      context.commit('setJudgeList', courtList)
      return courtList
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
  async deleteCase(context: { commit: Commit }, id: string) {
    try {
      await deleteCase(id)
      return 'ok'
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
  async updateHistoryCase(context: { commit: Commit }, param: any) {
    try {
      await updateCase(param)
      return 'ok'
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
  async queryCaseDetail(context: { commit: Commit }, caseId: string) {
    try {
      const caseDetail = await getCase(caseId)
      return caseDetail
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
  async createNewJudge(context: { commit: Commit }, param: any) {
    try {
      await createJudge(param)
      return 'ok'
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
}
