import { Commit } from 'vuex'
import { eMsg } from '@/utils/feedback'
import {
  updataTimeLimit,
  upDateBindRule,
  queryCourtBD,
  queryCourtByRegion,
  updateCourtBD,
  addRelationship,
} from '@/services/system/relationship'

export default {
  async addRelationship(context: { commit: Commit }, param: any) {
    try {
      await addRelationship(param)
      return 'ok'
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
  async updateTimeLimit(context: { commit: Commit }, param: any) {
    try {
      await updataTimeLimit(param)
      return 'ok'
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
  async updateBindRule(context: { commit: Commit }, param: any) {
    try {
      await upDateBindRule(param)
      return 'ok'
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
  async updateCourtBD(context: { commit: Commit }, param: any) {
    try {
      await updateCourtBD(param)
      return 'ok'
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
  async queryCourtBDByName(context: { commit: Commit }, nickName: string) {
    try {
      const courtBDList = await queryCourtBD(nickName)
      context.commit('setCourtBDList', courtBDList)
      return 'ok'
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
  async queryCourtByRegion(context: { commit: Commit }, param: any) {
    try {
      const courtList = await queryCourtByRegion(param)
      context.commit('setCourtList', courtList)
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
  async clearCourtList(context: { commit: Commit }) {
    try {
      context.commit('setCourtList', [])
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
}
