import { getProvince, getCityArea } from '@/services/target'
import { eMsg } from '@/utils/feedback'
export function getPCACode(areaCode: string) {
  const provinceMainCode = areaCode.substr(0, 2)
  const cityMainCode = areaCode.substr(2, 2)
  const areaMainCode = areaCode.substr(4, 2)
  if (Number(provinceMainCode) > 0) {
    if (Number(cityMainCode) > 0) {
      if (Number(areaMainCode) > 0) {
        return [
          `${provinceMainCode}0000`,
          `${provinceMainCode}${cityMainCode}00`,
          areaCode,
        ]
      } else {
        return [
          `${provinceMainCode}0000`,
          `${provinceMainCode}${cityMainCode}00`,
          '',
        ]
      }
    } else {
      return [`${provinceMainCode}0000`, '', '']
    }
  } else {
    return ['', '', '']
  }
}

export async function getRegionListByCode(areaCode: string) {
  try {
    const [province, city, area] = getPCACode(areaCode)
    const cityList = await getCityAreaList(province)
    const areaList = await getCityAreaList(city)
    return {
      cityList,
      areaList,
      province,
      city,
      area,
    }
  } catch (errMsg) {
    return eMsg(errMsg as string)
  }
}

export async function getProvinceList() {
  try {
    const provinceList = await getProvince()
    if (provinceList instanceof Array && provinceList.length > 0) {
      return provinceList.map(({ id, regionName }) => ({
        id,
        name: regionName,
      }))
    }
    return eMsg('数据异常')
  } catch (errMsg) {
    return eMsg(errMsg as string)
  }
}

export async function getCityAreaList(provinceCode: string) {
  try {
    const cityList = await getCityArea(provinceCode)
    if (cityList instanceof Array && cityList.length > 0) {
      return cityList.map(({ id, regionName }) => ({
        id,
        name: regionName,
      }))
    }
    return eMsg('数据异常')
  } catch (errMsg) {
    return eMsg(errMsg as string)
  }
}
