import Vue from 'vue'
import { Route, NavigationGuardNext } from 'vue-router'
import { setCurrentAuthority } from '@/components/authorized/permissions'
import store from '@/store'
import { unsetLocalStore, skipSSO } from '@/utils/auth'
import { ApiError } from '@/services/errors'
import { action as aUser, State as UserState } from '@/store/modules/user/types'

export default async function authGuards(
  to: Route,
  _: Route,
  next: NavigationGuardNext<Vue>,
) {
  const { user } = store.state as { user: UserState }
  if (!user.info.data || !user.routes.data) {
    // 如果没有用户信息和路由信息数据
    let roleSignList: string[]
    try {
      const { roleList } = await store.dispatch(`user/${aUser.info.fetch}`)
      roleSignList = (roleList as { roleSign: string }[]).map(
        item => item.roleSign,
      )
    } catch (err) {
      if ((err as ApiError).reason?.returnCode === '100001') {
        next(false)
        skipSSO(false, to.fullPath)
      } else {
        unsetLocalStore('token')
        next({ name: 'no-auth' })
      }
      throw new Error('exception mode')
    }
    let routeList: string[]
    try {
      const list = await store.dispatch(`user/${aUser.routes.fetch}`)
      routeList = list.concat('index')
    } catch (err) {
      unsetLocalStore('token')
      next({ name: 'no-auth' })
      throw new Error('no auth')
    }
    setCurrentAuthority(roleSignList, 'role')
    setCurrentAuthority(routeList)
  }
  next()
}
