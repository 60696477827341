// 打开窗口后的回调
import EventEmitter from 'eventemitter3'

class TaskDialogEvent {
  private EE = new EventEmitter()
  private eventName = 'task-dialog-event'

  public cancel() {
    this.EE.emit(this.eventName, false)
  }
  public confirm() {
    this.EE.emit(this.eventName, true)
  }
  public onConfirm() {
    return new Promise(resolve => {
      this.EE.once(this.eventName, (res: boolean) => {
        if (res) {
          resolve('ok')
        } else {
          resolve('cancel')
        }
      })
    })
  }
}

export const taskDialogEvent = new TaskDialogEvent()
