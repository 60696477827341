import { render, staticRenderFns } from "./SetTaskStatus.vue?vue&type=template&id=1f396809&scoped=true&"
import script from "./SetTaskStatus.vue?vue&type=script&lang=ts&"
export * from "./SetTaskStatus.vue?vue&type=script&lang=ts&"
import style0 from "./SetTaskStatus.vue?vue&type=style&index=0&id=1f396809&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f396809",
  null
  
)

export default component.exports