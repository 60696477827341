import { memoize } from 'lodash'
import { getDistrictList } from '@/services/department'
import PromiseMapCache from '@/utils/PromiseMapCache'

const getRegions = memoize(
  function (level: number, value: string | number) {
    return getDistrictList(null, level && value).then(list =>
      (list || []).map(item => ({
        value: parseInt(item.id),
        label: item.regionName,
      })),
    )
  },
  (level: number, value: string | number) => level + ':' + value,
)

getRegions.cache = new PromiseMapCache(getRegions.cache)

export { getRegions }
