import axios from 'axios'
import moment from 'moment'
import ApiPath from '@/utils/api-path'
import { ApiResult, Pagination } from '../types'
import {
  ReportFormValue,
  StatementFormValue,
  CorporeFormValue,
  TaskSummaryFormValue,
  UserTaskFormValue,
} from './types'
import message from '../message'

const CancelToken = axios.CancelToken

interface UserTaskListParams extends UserTaskFormValue {
  pagination: Pagination
}

function getReportList({
  regionId,
  courtName,
  caseNum,
  targetNum,
  targetName,
  startTime,
  endTime,
  targetStatus,
  pagination,
}: ReportFormValue & {
  pagination: Pagination
}) {
  const params = {
    regionId:
      Array.isArray(regionId) && regionId.length !== 0
        ? regionId[regionId.length - 1]
        : undefined,
    courtName: courtName ? courtName : undefined,
    caseNum: caseNum ? caseNum : undefined,
    targetNum: targetNum ? targetNum : undefined,
    targetName: targetName ? targetName : undefined,
    startTime: startTime ? moment(startTime).format('YYYY-MM-DD') : undefined,
    endTime: endTime ? moment(endTime).format('YYYY-MM-DD') : undefined,
    targetStatus: targetStatus === '' ? undefined : targetStatus,
    pageNum: pagination.currentPage,
    pageRow: pagination.pageSize,
  }
  const source = CancelToken.source()
  const promise = axios.post<ApiResult>(ApiPath.getTargetTaskList, params, {
    cancelToken: source.token,
  })
  return {
    promise: promise.then(({ data }) => {
      if (data.returnCode !== '100') {
        return Promise.reject(new Error(data.returnMsg))
      } else {
        return data.returnData as any
      }
    }),
    source,
  }
}

function getCaseStatementList({
  region,
  courtName,
  caseNum,
  startTime,
  endTime,
  salesStatus,
  pagination,
  targetName,
  linkMan,
  onPerson,
}: StatementFormValue & {
  pagination: Pagination
}) {
  const params = {
    regionId:
      Array.isArray(region) && region.length !== 0
        ? region[region.length - 1]
        : undefined,
    courtName: courtName ? courtName : undefined,
    caseNum: caseNum ? caseNum : undefined,
    startTime: startTime ? moment(startTime).format('YYYY-MM-DD') : undefined,
    endTime: endTime ? moment(endTime).format('YYYY-MM-DD') : undefined,
    salesStatus: salesStatus ? salesStatus : undefined,
    pageNum: pagination.currentPage,
    pageRow: pagination.pageSize,
    targetName: targetName ? targetName : undefined,
    linkMan: linkMan ? linkMan : undefined,
    onPerson: onPerson ? onPerson : undefined,
  }
  const source = CancelToken.source()
  const promise = axios.post<ApiResult>(ApiPath.getCaseStatementList, params, {
    cancelToken: source.token,
  })
  return {
    promise: promise.then(({ data }) => {
      if (data.returnCode !== '100') {
        return Promise.reject(new Error(data.returnMsg))
      } else {
        return data.returnData as any
      }
    }),
    source,
  }
}

function getCorporeSummaryList({
  region,
  courtName,
  startTime,
  endTime,
  pagination,
}: CorporeFormValue & {
  pagination: Pagination
}) {
  const params = {
    regionId:
      Array.isArray(region) && region.length !== 0
        ? region[region.length - 1]
        : undefined,
    courtName: courtName ? courtName : undefined,
    startTime: startTime ? moment(startTime).format('YYYY-MM-DD') : undefined,
    endTime: endTime ? moment(endTime).format('YYYY-MM-DD') : undefined,
    pageNum: pagination.currentPage,
    pageRow: pagination.pageSize,
  }
  const source = CancelToken.source()
  const promise = axios.post<ApiResult>(ApiPath.getCorporeSummaryList, params, {
    cancelToken: source.token,
  })
  return {
    promise: promise.then(({ data }) => {
      if (data.returnCode !== '100') {
        return Promise.reject(new Error(data.returnMsg))
      } else {
        return data.returnData as any
      }
    }),
    source,
  }
}

function getTaskSummaryList({
  region,
  courtName,
  userName,
  startTime,
  endTime,
  pagination,
}: TaskSummaryFormValue & {
  pagination: Pagination
}) {
  const params = {
    regionId:
      Array.isArray(region) && region.length !== 0
        ? region[region.length - 1]
        : undefined,
    courtName: courtName ? courtName : undefined,
    userName: userName ? userName : undefined,
    startTime: startTime ? moment(startTime).format('YYYY-MM-DD') : undefined,
    endTime: endTime ? moment(endTime).format('YYYY-MM-DD') : undefined,
    pageNum: pagination.currentPage,
    pageRow: pagination.pageSize,
  }
  const source = CancelToken.source()
  const promise = axios.post<ApiResult>(ApiPath.getTaskSummaryList, params, {
    cancelToken: source.token,
  })
  return {
    promise: promise.then(({ data }) => {
      if (data.returnCode !== '100') {
        return Promise.reject(new Error(data.returnMsg))
      } else {
        return data.returnData as any
      }
    }),
    source,
  }
}

function getUserTaskList({
  courtId,
  userId,
  startTime,
  endTime,
  pagination,
}: UserTaskListParams) {
  const params = {
    courtId,
    userId,
    startTime: startTime ? moment(startTime).format('YYYY-MM-DD') : undefined,
    endTime: endTime ? moment(endTime).format('YYYY-MM-DD') : undefined,
    pageNum: pagination.currentPage,
    pageRow: pagination.pageSize,
  }
  const source = CancelToken.source()
  const promise = axios.post<ApiResult>(ApiPath.getUserTaskList, params, {
    cancelToken: source.token,
  })
  return {
    promise: promise.then(({ data }) => {
      if (data.returnCode !== '100') {
        return Promise.reject(new Error(data.returnMsg))
      } else {
        return data.returnData as any
      }
    }),
    source,
  }
}

export default message({
  getReportList,
  getCaseStatementList,
  getCorporeSummaryList,
  getTaskSummaryList,
  getUserTaskList,
})
