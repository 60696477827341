var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('simple-table',{attrs:{"config":_vm.config,"index":_vm.indexMethod,"data":_vm.data,"loading":_vm.loading},scopedSlots:_vm._u([{key:"targetName",fn:function(ref){
var row = ref.row;
return [_c('a',{on:{"click":function($event){return _vm.goToTargetDetail(row.targetId)}}},[_vm._v(" "+_vm._s(row.targetName)+" ")])]}},{key:"netUrl",fn:function(ref){
var row = ref.row;
return [(row.netUrl)?_c('a',{attrs:{"href":row.netUrl,"target":"_blank"}},[_vm._v(" 查看 ")]):_vm._e()]}},{key:"tags",fn:function(ref){
var row = ref.row;
return [_c('static-tag',{attrs:{"tag-list":row.tags.map(function (ref) {
	var tagName = ref.tagName;

	return tagName;
})}})]}},{key:"handler",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showTagUpdateModal(row)}}},[_vm._v("打标签")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }