import { render, staticRenderFns } from "./JudicialCommissionView.vue?vue&type=template&id=3fcef2d4&scoped=true&"
import script from "./JudicialCommissionView.vue?vue&type=script&lang=ts&"
export * from "./JudicialCommissionView.vue?vue&type=script&lang=ts&"
import style0 from "./JudicialCommissionView.vue?vue&type=style&index=0&module=true&lang=less&"
import style1 from "./JudicialCommissionView.vue?vue&type=style&index=1&id=3fcef2d4&lang=scss&scoped=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "3fcef2d4",
  null
  
)

export default component.exports