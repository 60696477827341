var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-header',{attrs:{"title":"发票申请"}},[_c('template',{slot:"content"},[_c('sample-filter',{attrs:{"value":_vm.formValue},on:{"input":_vm.onSubmitForm}}),_c('sample-list',_vm._b({attrs:{"index-method":_vm.indexMethod},on:{"show-invoice":_vm.showInvoice,"refresh":_vm.refresh}},'sample-list',_vm.dataList,false)),_c('simple-pagination',_vm._b({on:{"size-change":function($event){return _vm.modifyPagination({ pageSize: $event })},"current-change":function($event){return _vm.modifyPagination({ currentPage: $event })}}},'simple-pagination',_vm.pagination,false)),_c('el-dialog',{staticStyle:{"min-width":"550px","padding-left":"226px"},attrs:{"visible":_vm.dialog.visible,"title":_vm.dialog.title,"top":_vm.dialog.top,"width":_vm.dialog.width,"fullscreen":_vm.dialog.fullscreen,"close-on-click-modal":false},on:{"close":_vm.onCloseDialog}},[_c('el-scrollbar',{style:({ height: _vm.dialog.height }),attrs:{"wrap-style":"overflow-x: auto;"}},[_c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onDialogResize),expression:"onDialogResize"}],staticClass:"dialog-resize"},[_c(_vm.dialog.type,{ref:"dialogRef",tag:"component",attrs:{"params":_vm.dialog.params}})],1)]),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[(_vm.dialog.params && _vm.dialog.params.schedule === 'file')?[_c('el-button',{on:{"click":_vm.onCloseDialog}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"danger","loading":_vm.dialog.confirm},on:{"click":_vm.onReturnDialogFile}},[_vm._v(" 退 回 ")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.dialog.confirm},on:{"click":_vm.onConfirmDialog}},[_vm._v(" 确 定 ")])]:(
            _vm.dialog.params &&
            [
              'serviceFee',
              'apply',
              'applyRed',
              'applyRedAgain',
              'applyAgain',
              'applyInvalid',
              'delayReason' ].includes(_vm.dialog.params.schedule)
          )?[_c('el-button',{on:{"click":_vm.onCloseDialog}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.dialog.confirm},on:{"click":_vm.onConfirmDialog}},[_vm._v(" 确 定 ")])]:_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onCloseDialog}},[_vm._v(" 关 闭 ")])],2)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }