import { State, mutation as mSample, action as aSample } from './types'
import SampleService from '@/services/sample'
import { ActionTree } from 'vuex'

const actionTree: ActionTree<State, State> = {
  [aSample.condition.modify]({ commit, dispatch }, payload) {
    commit(mSample.condition.modify, payload)
    return dispatch(aSample.dataList.fetch)
  },
  [aSample.pagination.modify]({ commit, dispatch }, payload) {
    commit(mSample.pagination.modify, payload)
    return dispatch(aSample.dataList.fetch)
  },
  [aSample.dataList.fetch]({ commit, state }) {
    const { promise, source } = SampleService.getDepartmentSampleList({
      ...state.condition,
      pagination: state.pagination,
    })
    if (state.dataList.source !== null) {
      state.dataList.source.cancel('Abandon')
    }
    commit(mSample.dataList.request, { source })
    commit(mSample.dataTotal.request, { source })
    return promise.then(
      value => {
        if (state.dataList.source === source) {
          commit(mSample.dataList.success, { data: value.list })
          commit(mSample.dataTotal.success, { data: value.totalCount })
        }
        return value.list
      },
      reason => {
        if (state.dataList.source === source) {
          commit(mSample.dataList.failure, { error: reason })
          commit(mSample.dataTotal.failure, { error: reason })
        }
        return Promise.reject(reason)
      },
    )
  },
}

export default actionTree
