import Vue from 'vue'
import { Route, NavigationGuardNext } from 'vue-router'
import store from '@/store'
import { skipSSO } from '@/utils/auth'

/**
 * 未登录时跳转登录页
 */
export default function auth(
  to: Route,
  _: Route,
  next: NavigationGuardNext<Vue>,
) {
  const { user } = store.state as any
  if (!user.logged) {
    next(false)
    skipSSO(false, to.fullPath)
    return
  }
  next()
}
