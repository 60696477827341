import { render, staticRenderFns } from "./DistributionApply.vue?vue&type=template&id=94a4cac2&scoped=true&"
import script from "./DistributionApply.vue?vue&type=script&lang=ts&"
export * from "./DistributionApply.vue?vue&type=script&lang=ts&"
import style0 from "./DistributionApply.vue?vue&type=style&index=0&id=94a4cac2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94a4cac2",
  null
  
)

export default component.exports