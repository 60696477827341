export default {
  setCourtList(state: any, list: any[]) {
    state.courtList = list.map(({ customerName, courtId }) => ({
      courtId: String(courtId),
      customerName,
    }))
  },
  setTeamBDList(state: any, list: any[]) {
    state.BDList = list.map((item: any) => ({
      ...item,
      userId: String(item.userId),
    }))
  },
  setJudgeList(state: any, list: any[]) {
    state.judgeList = list.map(({ contactsName, id }) => ({
      id: String(id),
      contactsName,
    }))
  },
  changeCreateCaseDialogVisible(state: any, visible: boolean) {
    state.createCaseDialogVisible = visible
  },
  changeCreateJudgeDialogVisible(state: any, visible: boolean) {
    state.createJudgeDialogVisible = visible
  },
  changeSimpleTargetListDialogVisible(state: any, visible: boolean) {
    state.targetSimpleListVisible = visible
  },
  simpleTargetListStartLoad(state: any) {
    state.targetSimpleListLoading = true
  },
  simpleTargetListFinishLoad(state: any, list: any[]) {
    state.targetSimpleList = list
    state.targetSimpleListLoading = false
  },
}
