import {
  onMounted,
  onUnmounted,
  ref,
  onBeforeMount,
} from '@vue/composition-api'
import { useThrottleFunc } from './ThrottleFunc'

export function useWindowSize(delay = 100) {
  const width = ref(0)
  const height = ref(0)

  function setSize() {
    width.value = window.innerWidth
    height.value = window.innerHeight
  }

  const { execute } = useThrottleFunc(setSize, delay)

  const onResize = () => execute()
  onBeforeMount(setSize)

  onMounted(() => {
    window.addEventListener('resize', onResize, { passive: true })
  })

  onUnmounted(() => {
    window.removeEventListener('resize', onResize)
  })

  return { height, width }
}
