import Vue from 'vue'
import { Route, NavigationGuardNext } from 'vue-router'
import { unsetLocalStore } from '@/utils/auth'
import { AuthorizedPass } from '@/components/authorized/permissions'

/**
 * 鉴定权限
 */
export default function authorize(
  to: Route,
  _: Route,
  next: NavigationGuardNext<Vue>,
) {
  if (to.name === 'index' && AuthorizedPass('home')) {
    // 新增首页转到工作台
    next({ name: 'home' })
  } else if (to.name === 'index' && AuthorizedPass('message')) {
    // 首页转到消息中心
    next({ name: 'message' })
  } else {
    const record = to.matched[1]
    const authority = record.meta.permission || record.name
    if (AuthorizedPass(authority)) {
      // 校验通过
      next()
    } else {
      // 帮助中心不做权限控制，将其过滤掉
      if (to.name === 'help-center') {
        next()
      } else {
        // 没有权限
        unsetLocalStore('token')
        next({ name: '403' })
      }
    }
  }
}
