import moment from 'moment'
import ApiPath from '@/utils/api-path'
import { getData, postData } from '../request'

export function withdrawCityReward(rewardId: number) {
  return getData(ApiPath.withdrawCityReward(rewardId))
}

export function closeCityReward(rewardId: number) {
  return getData(ApiPath.closeCityReward(rewardId))
}

export function confirmMyCityReward(distributionId: number) {
  return postData(ApiPath.confirmMyCityReward, { id: distributionId })
}

export function convertCityRewardValue(params: CityRewardListParams) {
  return {
    ...params,
    regionId: getLastItem(params.regionIds),
    regionIds: undefined,
  }
}

// 司辅提成管理
export async function getCityRewardList(
  params: CityRewardListParams,
  pages: CommonPages,
) {
  const res = await postData<CommonResult>(ApiPath.getCityRewardList, {
    ...convertCityRewardValue(params),
    pageNum: pages.currentPage,
    pageRow: pages.pageSize,
  })
  const list = res.list as unknown[] as CityRewardItemData[]
  return { list, total: res.totalCount }
}

export interface CityRewardListParams {
  regionIds?: string[] | null // 法院所在地
  distributionNo?: string // 提成编号
  rewardDistributionNo?: string // 提成分配编号
  distributionStatus?: CityRewardStatus // 状态
}

export interface CityRewardItemData {
  rewardId: number
  rewardDistributionNo: string
  regionName: string
  distributionNo: string
  rewardAmount: number
  userCount: number
  status: CityRewardStatus
  updateUserName: string
  updateTime: string
}

export const OCityRewardStatus = {
  WAIT_CONFIRM: 1, // 待确认
  WAIT_FIRST_TRIAL: 2, // 待审核
  WAIT_ARCHIVE: 3, // 待归档
  ARCHIVED: 4, // 已归档
  GIVEN: 5, // 已发放
  FIRST_TRIAL_BACK: 6, // 审核驳回
  WITHDRAW: 7, // 已撤回
  CLOSED: 8, // 已关闭
  HRBP_BACK: 9, // HRBP驳回
} as const

export type CityRewardStatus =
  typeof OCityRewardStatus[keyof typeof OCityRewardStatus]

export function getCityRewardCityList() {
  return getData<{ list: CityRewardCityItem[] }>(ApiPath.getCityRewardCityList)
}

interface CityRewardCityItem {
  id: number
  name: string
}

export function getCityRewardOverview(regionId?: number | null) {
  return postData<{
    allRewardAmount: number
    applyRewardAmount: number
    canRewardAmount: number
  } | null>(ApiPath.getCityRewardOverview, { regionId })
}

export function convertMyCityRewardValue(params: MyCityRewardListParams) {
  const { archiveStartTime: startTime, archiveEndTime: endTime } = params
  return {
    ...params,
    regionIds: getLastItem(params.regionIds),
    archiveStartTime: startTime && moment(startTime).format('YYYY-MM-DD'),
    archiveEndTime: endTime && moment(endTime).format('YYYY-MM-DD'),
  }
}

export function newConvertMyCityRewardValue(params: MyCityRewardListParams) {
  const { archiveStartTime: startTime, archiveEndTime: endTime } = params
  return {
    ...params,
    regionIds: newGetLastItem(params.regionIds),
    archiveStartTime: startTime && moment(startTime).format('YYYY-MM-DD'),
    archiveEndTime: endTime && moment(endTime).format('YYYY-MM-DD'),
  }
}
// 个人城市奖金
export async function getMyCityRewardList(
  params: MyCityRewardListParams,
  pages: CommonPages,
) {
  const res = await postData<CommonResult>(ApiPath.selMyCityRewardList, {
    ...newConvertMyCityRewardValue(params),
    pageNum: pages.currentPage,
    pageRow: pages.pageSize,
  })
  const list = res.list as unknown[] as CityRewardItemData[]
  return {
    list,
    total: res.totalCount,
    personalAmountSum: res.personalAmountSum as number,
  }
}

export interface MyCityRewardListParams {
  regionIds?: string[] | null // 法院所在地
  distributionNo?: string // 提成编号
  rewardDistributionNo?: string // 提成分配编号
  confirmStatus?: 0 | 1 | null // 是否确认
  distributionStatus?: CityRewardStatus // 状态
  archiveStartTime?: Date | null
  archiveEndTime?: Date | null
  userName?: string | null // 员工姓名
}

export interface MyCityRewardItemData {
  rewardId: number
  rewardDistributionNo: string
  regionName: string
  distributionId: number
  distributionNo: string
  rewardAmount: number
  userName: number
  status: CityRewardStatus
  updateTime: string
}

// 查询操作日志
export function getCommissionLog(entryId: number) {
  return getData<CommissionLog[]>(ApiPath.getCityRewardLog(entryId))
}

export interface CommissionLog {
  id: number
  operateTime: string
  operateType: number
  operateTypeDesc: string
  operatePersonName: null
  roleName: string
  consumeTime: number | null // 消耗时间
  remark: string | null
  nextType: string
  nextOperatePerson: string
}

export async function getCityRewardDetail(rewardId: number) {
  return getData<CityRewardDetail>(ApiPath.getCityRewardDetail(rewardId))
}

export interface CityRewardDetail {
  cityCode: number
  regionName: string
  rewardDistributionNo: string
  rewardAmount: number
  distributions: CityRewardDetailDistribution[]
  users: CityRewardDetailUser[]
}

export interface CityRewardDetailDistribution {
  id: number
  courtName: string // 法院
  distributionNo: string
  targetName: string
  distributionAmount: number
}

export interface CityRewardDetailUser {
  id: number
  userId: number
  userName: string
  proportion: number
  amount: number
}

export interface customerItem {
  customerId: string
  customerName: string
}
export interface CityRewardFormData {
  rewardId: number | null
  regionIds: string[] | null
  courtId?: string | undefined
  distributions: PostCityDistributionItem[]
  users: CityRewardUserFormItem[]
  options: Array<customerItem>
}

export interface CityRewardUserFormItem {
  id: number | null
  userId: number
  userName: string
  proportion: string
  amount: string
}

export function getCourtBind(regionId: string[]) {
  return postData(ApiPath.getCourtBind, {
    regionId:
      Array.isArray(regionId) && regionId.length !== 0
        ? regionId[regionId.length - 1]
        : undefined,
    pageNum: '9999',
  })
}

export function editCityRewardData(data: AddCityRewardData) {
  const url = data.rewardId
    ? ApiPath.updateCityRewardData
    : ApiPath.addCityRewardData
  const res = postData(url, {
    ...data,
    id: data.rewardId,
    rewardId: undefined,
    regionId: getLastItem(data.regionIds),
    regionIds: undefined,
  })
  return res
}

export interface AddCityRewardData {
  rewardId: number | null
  regionIds: string[] | null
  distributions: PostCityDistributionItem[]
  users: CityRewardUserData[]
}

export interface PostCityDistributionItem {
  id: number | null
  courtId: number
  courtName: string // 法院
  distributionId: number
  distributionNo: string
  targetName: string
  distributionAmount: number
}

export interface CityRewardUserData {
  id: number | null
  userId: number
  userName: string
  proportion: number
  amount: number
}

// 可分配奖金提成列表
export async function getCityDistributionList(
  params: CityDistributionListParams,
) {
  const res = await postData<CommonResult>(ApiPath.getCityDistributionList, {
    ...params,
    courtId: params.courtId ? Number(params.courtId) : undefined,
    regionId: getLastItem(params.regionIds),
    regionIds: undefined,
    pageFlag: false,
  })
  const list = res.list as unknown[] as CityDistributionItemData[]
  return { list }
}

export interface CityDistributionListParams {
  regionIds: string[] | null // 法院所在地
  rewardId?: number | null // 当前城市奖金记录
  courtName?: string // 法院名称
  distributionNo?: string // 提成编号
  targetName?: string // 标的物名称
  targetNo?: string // 标的物编号
  distributionAmount: string // 分配方案
  program?: string | number // 分配金额
  courtId?: string | null
}

export interface CityDistributionItemData {
  courtId: number
  courtName: string // 法院
  distributionId: number // 提成申请
  distributionNo: string // 提成编号
  targetNo: string // 标的物编号
  targetName: string // 标的物名称
  amount: number // 城市奖金
}

export function getCityMemberList(params: {
  regionId: string
  userName: string
}) {
  return postData<{ userId: number; nickname: string }[]>(
    ApiPath.getCityMemberList,
    params,
  )
}

export function auditCityReward(params: AuditCityRewardParams) {
  return postData(ApiPath.auditAndArchivedCityReward, params)
}

interface AuditCityRewardParams {
  id: number
  status: CityRewardStatus
  remark: string
}

interface CommonPages {
  currentPage: number
  pageSize: number
}

interface CommonResult {
  [index: string]: unknown
  list: Record<string, unknown>[]
  totalCount: number
}

function newGetLastItem<T>(arr?: T[] | null) {
  return Array.isArray(arr) && arr.length !== 0
    ? arr.map((item: any) => Number(item[1]))
    : undefined
}

export function getLastItem<T>(arr?: T[] | null) {
  return Array.isArray(arr) && arr.length !== 0
    ? arr[arr.length - 1]
    : undefined
}
