import ApiPath from '@/utils/api-path'
import { getData, postData } from '../request'

export function getDistributionStatusList() {
  return postData(ApiPath.getDistributionStatusList)
}

export function getDistributionManageList(params: any) {
  return postData(ApiPath.getDistributionManageList, params)
}

export function addDistributionDetail(params: any) {
  return postData(ApiPath.addDistributionDetail, params)
}

export function auditDistribution(params: any) {
  return postData(ApiPath.auditDistribution, params)
}

export function getDistributionRecord(entryId: string | number) {
  return postData(ApiPath.getDistributionRecord, { entryId })
}

export function getDistributionData(entryId: string | number) {
  return postData(ApiPath.editDistributionDetail, {
    entryId,
    canDistribution: 0,
  })
}

export function getLatestDistributionData(entryId: string | number) {
  return postData(ApiPath.editDistributionDetail, {
    entryId,
    canDistribution: 1,
  })
}

export function getAlarmCountOfTarget(targetId: string | number) {
  return getData<number | null>(ApiPath.queryAlarmCountOfTarget(targetId))
}

export function confirmDistribution(
  entryId: string | number,
  entryDistributionId: string | number,
) {
  return postData(ApiPath.confirmDistribution, {
    entryId,
    entryDistributionId,
  })
}
