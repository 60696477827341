import { State, mutation as mReport, action as aReport } from './types'
import ReportService from '@/services/report'
import { ActionTree } from 'vuex'

const actionTree: ActionTree<State, State> = {
  [aReport.condition.modify]({ commit, dispatch }, payload) {
    commit(mReport.condition.modify, payload)
    return dispatch(aReport.dataList.fetch)
  },
  [aReport.pagination.modify]({ commit, dispatch }, payload) {
    commit(mReport.pagination.modify, payload)
    return dispatch(aReport.dataList.fetch)
  },
  [aReport.dataList.fetch]({ commit, state }) {
    const { promise, source } = ReportService.getTaskSummaryList({
      ...state.condition,
      pagination: state.pagination,
    })
    if (state.dataList.source !== null) {
      state.dataList.source.cancel('Abandon')
    }
    commit(mReport.dataList.request, { source })
    commit(mReport.dataTotal.request, { source })
    return promise.then(
      value => {
        if (state.dataList.source === source) {
          commit(mReport.dataList.success, { data: value.list })
          commit(mReport.dataTotal.success, { data: value.totalCount })
        }
        return value.list
      },
      reason => {
        if (state.dataList.source === source) {
          commit(mReport.dataList.failure, { error: reason })
          commit(mReport.dataTotal.failure, { error: reason })
        }
        return Promise.reject(reason)
      },
    )
  },
}

export default actionTree
