import moment from 'moment'

export function viewTaskTimeDisabled(
  date: string,
  startTime: string,
  endTime: string,
) {
  if (startTime && endTime) {
    const startDate = moment(startTime)
    startDate.hour(23)
    startDate.minute(59)
    startDate.second(59)
    const endDate = moment(endTime)
    endDate.hour(23)
    endDate.minute(59)
    endDate.second(59)
    return !moment(date).isBetween(startDate, endDate)
  } else {
    return false
  }
}
