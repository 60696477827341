import { render, staticRenderFns } from "./MultipleAuction.vue?vue&type=template&id=205cd385&scoped=true&"
import script from "./MultipleAuction.vue?vue&type=script&lang=ts&"
export * from "./MultipleAuction.vue?vue&type=script&lang=ts&"
import style0 from "./MultipleAuction.vue?vue&type=style&index=0&id=205cd385&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "205cd385",
  null
  
)

export default component.exports