import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state: {
    courtBDList: [], // 查询出的法院BD列表
    courtList: [], // 根据地区检索出的法院列表
  },
  mutations,
  actions,
}
