import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import cases from './modules/cases'
import sample from './modules/sample'
import invoice from './modules/invoice'
import report from './modules/report'
import task from './modules/task'
import target from './modules/target'
import relationship from './modules/system/relationship'
import workbench from './modules/workbench'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  modules: {
    task,
    user,
    cases,
    target,
    sample,
    invoice,
    report,
    relationship,
    workbench,
  },
})
