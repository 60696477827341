export const TaskSummary = () =>
  import(/* webpackChunkName: "report-form" */ './task-summary/TaskSummary.vue')

export const CaseStatements = () =>
  import(
    /* webpackChunkName: "report-form" */ './case-statements/CaseStatements.vue'
  )

export const CrossAreaTarget = () =>
  import(
    /* webpackChunkName: "report-form" */ './cross-area-target/CrossAreaTarget.vue'
  )

export const ServiceCorporeSummary = () =>
  import(
    /* webpackChunkName: "report-form" */ './service-corpore-summary/ServiceCorporeSummary.vue'
  )

export const ServiceTaskSummary = () =>
  import(
    /* webpackChunkName: "report-form" */ './service-task-summary/ServiceTaskSummary.vue'
  )

export const ServiceTaskUser = () =>
  import(
    /* webpackChunkName: "report-form" */ './service-task-summary/user/ServiceTaskUser.vue'
  )

export const OperationWeekly = () =>
  import(
    /* webpackChunkName: "report-form" */ './operation-weekly/OperationWeekly.vue'
  )

export const CourtOperationAnalysis = () =>
  import(
    /* webpackChunkName: "report-form" */ './court-operation-analysis/CourtOperationAnalysis.vue'
  )

export const OverallOperation = () =>
  import(
    /* webpackChunkName: "report-form" */ './overall-operation/OverallOperation.vue'
  )

export const ProvincialDevelopService = () =>
  import(
    /* webpackChunkName: "report-form" */ './provincial-develop-service/ProvincialDevelopService.vue'
  )

export const PointCourtServiceTarget = () =>
  import(
    /* webpackChunkName: "report-form" */ './point-court-service-target/PointCourtServiceTarget.vue'
  )

export const InvoiceInformation = () =>
  import(
    /* webpackChunkName: "report-form" */ './invoice-information/InvoiceInformation.vue'
  )
