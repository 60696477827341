import {
  onMounted,
  onUnmounted,
  ref,
  Ref,
  unref,
  watch,
} from '@vue/composition-api'
import ResizeObserver from 'resize-observer-polyfill'

export function useResize(refAbleEl: RefAbleElHTMLElement) {
  let observer: ResizeObserver

  const elEntry = ref<ResizeObserverEntry | null>(null)

  watch(
    () => unref(refAbleEl),
    el => {
      if (observer) {
        observer.disconnect()
        if (el) {
          observer.observe(el)
        }
      }
    },
  )

  onMounted(() => {
    observer = new ResizeObserver(([entry]: ResizeObserverEntry[]) => {
      elEntry.value = entry
    })
    const el = unref(refAbleEl)
    if (el) {
      observer.observe(el)
    }
  })

  onUnmounted(() => {
    if (observer) {
      observer.disconnect()
    }
  })

  return elEntry
}

export type RefAbleElHTMLElement =
  | Ref<HTMLElement | null | undefined>
  | HTMLElement
