export default {
  setTaskCheckDialogVisible(state: any, visible: boolean) {
    state.taskCheckDialogVisible = visible
  },
  setSendMsg(state: any, value: boolean) {
    state.sendMsg = value
  },
  setSendMsgDisabled(state: any, value: boolean) {
    state.sendMsgDisabled = value
  },
  setViewNum(state: any, num: boolean) {
    state.viewNum = num
  },
  setModuleName(state: any, moduleName: string) {
    state.moduleName = moduleName
    switch (moduleName) {
      case '任务编辑':
        state.moduleDes =
          '编辑后所有该任务的看样预约都会随之变更、不可恢复，是否确认编辑'
        break
      case '任务删除':
        state.moduleDes =
          '删除后所有该任务的看样预约都会随之变更、不可恢复，是否确认删除'
        break
      case '标的物删除':
        state.moduleDes =
          '删除后所有该标的物的看样预约都会随之变更、不可恢复，是否确认删除'
        break
      case '拍次编辑':
        state.moduleDes =
          '编辑后所有该拍次的看样预约都会随之变更、不可恢复，是否确认编辑'
        break
      case '拍次删除':
        state.moduleDes =
          '删除后所有该拍次的看样预约都会随之变更、不可恢复，是否确认删除'
        break
      default:
        state.moduleDes =
          '操作后所有相关的看样预约都会随之变更、不可恢复，是否确认操作'
        break
    }
  },
}
