import moment from 'moment'
import ApiPath from '@/utils/api-path'
import request, { postData, getData } from '../request'
import { downloadFile } from '../common'

export enum NoticeLevel {
  HIGH = 1,
  MIDDLE,
  LOW,
}

export interface NotifyItem {
  alarmId: number
  alarmTitle: string
  content: string
  courtName: string
  createTime: string
  dateId: number
  dateName: string
  dateType: string
  deleteStatus: number
  id: number
  level: NoticeLevel
  levelStr: string
  readStatus: number
  readStatusStr: string
  receiverName: string
  receivers: number
}

export interface NotifyCount {
  allLogNum: number
  lowLevelNum: number
  lowLevelList: NotifyItem[]
  middleLevelNum: number
  middleLevelList: NotifyItem[]
  highLevelNum: number
  highLevelList: NotifyItem[]
}

export function getNotifyCount() {
  return postData<NotifyCount>(ApiPath.getNotifyCount, {})
}

export function readNotify(params: { id: number }) {
  return postData<NotifyItem>(ApiPath.readNotify, params)
}

export interface AsyncData<T = any> {
  data: T | null
  error: Error | null
  loading: boolean
  source: AbortController | null
}

export interface FormValue {
  region?: string[] | null
  courtName?: string
  level?: 'low' | 'middle' | 'high' | null
  alarmTitle?: string
  targetNo?: string
  dateName?: string
  startTime?: Date
  endTime?: Date
  deleteStatus?: number
  updateStartTime?: Date
  updateEndTime?: Date
  // readStatus?: 'nonRead' | 'read' | null;
}

function convertFormValue(params: FormValue) {
  const {
    region,
    courtName,
    level,
    alarmTitle,
    targetNo,
    dateName,
    startTime,
    endTime,
    deleteStatus,
    updateStartTime,
    updateEndTime,
  } = params
  return {
    regionId: (region && region[region.length - 1]) || undefined,
    courtName: courtName || undefined,
    level: level ? { low: 3, middle: 2, high: 1 }[level] : undefined,
    alarmTitle: alarmTitle || undefined,
    targetNo: targetNo || undefined,
    dateName: dateName || undefined,
    startTime: startTime ? moment(startTime).format('YYYY-MM-DD') : undefined,
    endTime: endTime ? moment(endTime).format('YYYY-MM-DD') : undefined,
    deleteStatus: typeof deleteStatus === 'number' ? deleteStatus : undefined,
    updateStartTime: updateStartTime
      ? moment(updateStartTime).format('YYYY-MM-DD')
      : undefined,
    updateEndTime: updateEndTime
      ? moment(updateEndTime).format('YYYY-MM-DD')
      : undefined,
  }
}

export interface Pagination {
  total: number
  pageSize: number
  currentPage: number
}

export function getNotifyList(
  signal: AbortSignal | null,
  val: FormValue,
  page: Pagination,
) {
  return request<{ list: NotifyItem[]; totalCount: number }>(
    ApiPath.getNotifyList,
    {
      method: 'POST',
      signal,
      body: JSON.stringify({
        ...convertFormValue(val),
        pageNum: page.currentPage,
        pageRow: page.pageSize,
      }),
    },
  )
}

export function exportNotifyList(val: FormValue) {
  const params = convertFormValue(val)
  downloadFile(ApiPath.exportNotifyList, params)
}

export function dealNotify() {
  return postData<NotifyCount>(ApiPath.dealNotify, {})
}

export function targetOwnCheck(targetId: string) {
  return getData(ApiPath.targetOwnPermissionCheck(targetId))
}

export function queryTargetAlarmCount(targetId: string) {
  return getData(ApiPath.queryAlarmCountOfTarget(targetId))
}
