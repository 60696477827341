export default {
  userName: (state: any) => {
    const userInfo = state.info.data
    return userInfo !== null ? userInfo.nickName || userInfo.userName : '未知'
  },
  isCountry: getRole('data_all'),
  isProvince: getRole('data_province'),
  isCity: getRole('data_city'),
  isBD: getRole('法服BD'),
}

function getRole(roleSign: string) {
  return (state: any) => {
    if (!state.info.data) return false
    const { roleList } = state.info.data
    return roleList.some((el: any) => el.roleSign === roleSign)
  }
}
