import { post, get } from '@/utils/request'
import ApiPath from '@/utils/api-path'
import { postData, putData } from '../request'

export const AuctionTypeOptions = [
  { value: '1', text: '待拍' },
  { value: '2', text: '一拍' },
  { value: '3', text: '二拍' },
  { value: '4', text: '变卖' },
  { value: '5', text: '结束' },
  { value: '6', text: '成交' },
  { value: '7', text: '其他' },
]

export function getTargetList(id?: string) {
  return post(ApiPath.targetList, { id })
}

export function getFirstTargetType() {
  return post(ApiPath.targetType, {})
}
export function getSecondTargetType(targetType: string) {
  return post(ApiPath.targetMinType, { targetType })
}

export function getPropertyList() {
  return post(ApiPath.propertyList, {
    dicCode: 'property_min_type',
    parentCode: 'target_tenure',
  })
}

export function getProvince() {
  return post(ApiPath.city, {})
}
export function getCityArea(code?: string) {
  return post(ApiPath.city, { id: code })
}
export function createTarget(data: any) {
  return postData(ApiPath.addTarget, data)
}
export function deleteTarget(id: string, sendMsg: boolean) {
  return post(ApiPath.deleteTarget, { id, sendMsg })
}

export function getCourtUser(courtId?: string) {
  return postData(ApiPath.getCourtUser, { courtId })
}
export function addTask(param: any) {
  return post(ApiPath.taskCreate, param)
}
export function addCrossTask(param: any) {
  return post(ApiPath.taskCrossCreate, param)
}
export function setCrossEntrust(param: {
  targetId: number
  crossEntrust: boolean
  crossRemark: string
}) {
  return putData(ApiPath.setCrossEntrust, {
    targetId: param.targetId,
    crossEntrust: param.crossEntrust,
    crossRemark: param.crossRemark,
  })
}

export function getNewestAuction(targetId: string | number) {
  return get(ApiPath.selectNewAuction(targetId))
}

export function getCustomerNameByPhone(phone: string | number) {
  return get(ApiPath.getCustomerNameByPhone(phone))
}

export function getTargetDetail(id: string) {
  return post(ApiPath.targetDetail, { id })
}

export function updateTargetDetail(param: any) {
  return post(ApiPath.updateTarget, param)
}

export function updateTargetAuctionData(param: any) {
  return post(ApiPath.updateTargetAuctionData, param)
}

export function updateTargetSpecialData(param: any) {
  return post(ApiPath.updateTargetSpecialData, param)
}

export function deleteAuction(id: string) {
  return post(ApiPath.deleteAuction, { id })
}

export function getTaskTrace(targetId: string) {
  return postData(ApiPath.taskTrace, { targetId })
}

// 任务类型获取
export function getTaskType(courtId: string) {
  return post(ApiPath.taskType, { courtId })
}

// 根据url获取拍卖信息
export function getAuctionByUrl(auctionUrl: string) {
  return post(ApiPath.getAuctionByUrl, { auctionUrl })
}

export function getTaskDetail(id: string) {
  return post(ApiPath.taskDetail, { id })
}

// 编辑看样任务
export function updateViewTaskTime(
  id: string,
  finishTime: string,
  sendMsg: boolean,
) {
  return post(ApiPath.updateViewTaskTime, { id, finishTime, sendMsg })
}

/**
 * 资料归档
 */
export function archiveDocument(param: string[]) {
  return post(ApiPath.documentArchive, { docuList: param })
}
/**
 * 资料归档撤回
 */
export function withdrawDocumentArchive(id: string) {
  return post(ApiPath.documentArchiveWithdraw, { id })
}

// 消息中心

export function getMessageList(pageNum: number, pageRow: number) {
  return post(ApiPath.messageList, {
    type: 1,
    pageNum,
    pageRow,
  })
}

export function getMessageDetail(id: string | number) {
  return post(ApiPath.messageDetail, { id })
}
