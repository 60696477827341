var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('simple-table',{ref:"table",attrs:{"config":_vm.config,"index":_vm.indexMethod,"data":_vm.data,"loading":_vm.loading,"span-method":_vm.objectSpanMethod},scopedSlots:_vm._u([{key:"reservePrice",fn:function(ref){
var row = ref.row;
return _c('span',{},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(row.reservePrice))+" ")])}},{key:"billing",fn:function(ref){
var row = ref.row;
return _c('span',{},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(row.billing))+" ")])}},{key:"statusStr",fn:function(ref){
var row = ref.row;
return [(row.status !== 3)?_c('span',[_vm._v(_vm._s(row.statusStr || '-'))]):_c('div',[_c('div',[_vm._v(_vm._s(row.statusStr))]),_c('div',[_vm._v(_vm._s(_vm._f("backFormat")(row.backReason)))])])]}},{key:"applyBill",fn:function(ref){
var row = ref.row;
return _c('span',{},[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showInvoice(row, 'apply')}}},[_vm._v("开票")])],1)}},{key:"endTime",fn:function(ref){
var row = ref.row;
return _c('span',{},[_vm._v(_vm._s(row.endTime || '-'))])}},{key:"operation",fn:function(ref){
var row = ref.row;
return [(_vm.hasBDRole && _vm.userId === row.userId && !row.serviceFee)?_c('div',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showInvoice(row, 'serviceFee')}}},[_vm._v(" 收费 ")])],1):(row.serviceFee === 1)?_c('div',[(_vm.hasBDRole && _vm.userId === row.userId && row.status === 0)?_c('div',[_vm._v(" - ")]):(
          _vm.hasBDRole &&
          _vm.userId === row.userId &&
          (row.status === 3 || row.status === 8)
        )?_c('div',{staticClass:"apply-op"},[(!row.redInvoice)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showInvoice(row, 'applyAgain')}}},[_vm._v(" 再次申请 ")]):_vm._e(),(!row.redInvoice)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showInvoice(row, 'applyInvalid')}}},[_vm._v(" 申请作废 ")]):_vm._e(),(row.redInvoice)?[(row.blueStatus === 4)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showInvoice(row, 'applyRedAgain')}}},[_vm._v(" 再次申请 ")]):_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showInvoice(row, 'examView')}}},[_vm._v(" 查看 ")])]:_vm._e()],2):(_vm.hasBDRole && _vm.userId === row.userId && row.status === 4)?_c('div',{staticClass:"apply-op"},[(!row.redInvoice && !row.redInvoiceId)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showInvoice(row, 'applyRed')}}},[_vm._v(" 冲红 ")]):_vm._e(),(!row.redInvoice)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showInvoice(row, 'delayReason')}}},[_vm._v(" 回款超期 ")]):_vm._e(),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showInvoice(row, 'examView')}}},[_vm._v(" 查看 ")])],1):_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){_vm.showInvoice(
            row,
            [8, 9].includes(row.status)
              ? 'examInvalidView'
              : [7].includes(row.status)
              ? 'applyInvalidView'
              : [2, 3, 4, 5, 6].includes(row.status)
              ? 'examView'
              : 'applyView'
          )}}},[_vm._v(" 查看 ")]),(_vm.hasBDRole && _vm.userId === row.userId && row.status === 5)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showInvoice(row, 'file')}}},[_vm._v(" 归档 ")]):_vm._e()],1):_c('span',[_vm._v("-")])]}},{key:"reviewFlow",fn:function(ref){
          var row = ref.row;
return [(row.serviceFee === 1 && row.status !== 0)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showInvoice(row, 'flow')}}},[_vm._v(" 查看 ")]):_c('span',[_vm._v("-")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }