import { toRequest, toSuccess, toFailure } from '@/utils/store-types'
import { mutation as mUser } from './types'

import { Cancel, CancelTokenSource } from 'axios'

interface State {
  [propName: string]: any
}

export default {
  [mUser.logged.modify](state: State, logged: boolean) {
    state.logged = logged
  },
  // 用户信息
  [mUser.info.request](
    state: State,
    { source }: { source: CancelTokenSource },
  ) {
    toRequest(state.info, source)
  },
  [mUser.info.success](state: State, { data }: { data: any }) {
    toSuccess(state.info, data)
  },
  [mUser.info.failure](state: State, { error }: { error: Error | Cancel }) {
    toFailure(state.info, error)
  },
  // 可访问的权限路由
  [mUser.routes.request](
    state: State,
    { source }: { source: CancelTokenSource },
  ) {
    toRequest(state.routes, source)
  },
  [mUser.routes.success](state: State, { data }: { data: any }) {
    toSuccess(state.routes, data)
  },
  [mUser.routes.failure](state: State, { error }: { error: Error | Cancel }) {
    toFailure(state.routes, error)
  },
}
