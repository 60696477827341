import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state: {
    courtList: [],
    BDList: [],
    judgeList: [],
    createCaseDialogVisible: false,
    createJudgeDialogVisible: false,
    targetSimpleListVisible: false,
    targetSimpleListLoading: false,
    targetSimpleList: [],
  },
  mutations,
  actions,
}
