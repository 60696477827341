import { Commit } from 'vuex'
import { deleteTarget, getCourtUser, getTaskType } from '@/services/target'
import { eMsg } from '@/utils/feedback'

export default {
  async removeTarget(context: { commit: Commit }, param: any) {
    try {
      await deleteTarget(param.id, param.sendMsg)
      return 'ok'
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
  async getCourtUser(context: { commit: Commit }, param: any) {
    try {
      const res = await getCourtUser(param)
      context.commit('setCourtUser', res)
    } catch (err) {
      eMsg((err as Error).message)
    }
  },
  // taskTypes
  async getCourtTaskTypes(context: { commit: Commit }, courtId: string) {
    try {
      const res = await getTaskType(courtId)
      context.commit('setCourtTaskType', res)
      return 'ok'
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
}
