import { render, staticRenderFns } from "./AddForm.vue?vue&type=template&id=54c30c91&scoped=true&"
import script from "./AddForm.vue?vue&type=script&lang=ts&"
export * from "./AddForm.vue?vue&type=script&lang=ts&"
import style0 from "./AddForm.vue?vue&type=style&index=0&id=54c30c91&lang=less&scoped=true&"
import style1 from "./AddForm.vue?vue&type=style&index=1&module=true&lang=less&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "54c30c91",
  null
  
)

export default component.exports