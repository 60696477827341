import registration from './registration'
import departmentRegistration from './department-registration'
import departmentSample from './department-sample'

export default {
  namespaced: true,
  modules: {
    registration,
    departmentRegistration,
    departmentSample,
  },
}
