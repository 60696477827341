import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state: {
    condition: {},
    dataList: {
      data: null,
      error: null,
      loading: false,
      source: null,
    },
    dataTotal: {
      data: 0,
      error: null,
      loading: false,
      source: null,
    },
    pagination: {
      pageSize: 15,
      currentPage: 1,
    },
  },
  mutations,
  actions,
}
