import { Commit } from 'vuex'
import {
  getPermissionRegionOrCourt,
  getOverviewData,
  getPlatFormTargetData,
  getCourtDataList,
} from '@/services/workbench'
import { eMsg } from '@/utils/feedback'

export default {
  async getPermissionCourt(context: { commit: Commit }) {
    try {
      const res = await getPermissionRegionOrCourt()
      context.commit('setCourtList', res.courts || [])
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },

  async getPermissionRegion(context: { commit: Commit }, isCountry = false) {
    try {
      const res = await getPermissionRegionOrCourt()
      context.commit('setRegionList', res.regions || [])
      if (res.regions && res.regions[0] && !isCountry) {
        context.commit('setSelectId', res.regions[0].id)
      }
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },

  async getCourtDataList(context: { commit: Commit }, param: any) {
    try {
      context.commit('setIncomeListLoading')
      const res = await getCourtDataList(param)
      context.commit('setIncomeList', res.list || [])
      return 'ok'
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },

  async getOverviewData(context: { commit: Commit }, param: any) {
    try {
      context.commit('setTargetTradingLoading')
      const res = await getOverviewData(param)
      context.commit('setTargetTradingList', res)
      context.commit('setLastSixMonthList', res.lastSixMonth || [])
      context.commit('setServiceAuctionList', res.currentMonthDay || [])
      return 'ok'
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
  async getPlatFormTargetData(context: { commit: Commit }, param: any) {
    try {
      const res = await getPlatFormTargetData(param)
      context.commit('setTargetNumList', res)
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
}
