import { onUnmounted } from '@vue/composition-api'

export function useThrottleFunc(func: () => void, delay = 0) {
  let stopped = false
  let timeId: number | null = null
  let last: number | null = null

  function execute(ms = delay) {
    if (stopped) {
      return
    }
    cancel()
    delay = ms
    const current = Date.now()
    if (!last || last + ms < current) {
      func()
      last = current
    } else {
      timeId = setTimeout(execute, last + ms - current) as unknown as number
    }
  }

  function cancel() {
    if (timeId) {
      clearTimeout(timeId)
      timeId = null
    }
  }

  onUnmounted(() => {
    stopped = true
    cancel()
  })

  return { execute, cancel }
}
