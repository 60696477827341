/**
 * 判断是否是一个限定小数位数的有效数字
 * @param value 输入值
 * @param digits 小数位数, 默认2位
 */
export default function (
  value: string | number | null | undefined,
  digits = 2,
) {
  if (value === '' || value === null || value === undefined) {
    return false
  }
  const execArray = /^(?:-\d+\.?|\.)(\d*)?$/.exec(
    typeof value === 'number' ? value.toString() : value,
  )
  return (
    execArray !== null &&
    (execArray[1] === undefined || execArray[1].length <= digits)
  )
}
