interface MenuItem {
  status: string
  permissionSort: number
  urlContent: string
  menuList: MenuItem[] | null
}

interface RoleItem {
  applicationId: 2
  id: number
  roleName: string
  roleSign: string
}

function flattenDepth(
  list: MenuItem[],
  getChild: (item: MenuItem) => MenuItem[] | null,
) {
  const temp = []
  let pending = list.concat()
  while (pending.length > 0) {
    const item = pending.shift() as MenuItem
    temp.push(item)
    const child = getChild(item)
    if (Array.isArray(child)) {
      pending = child.concat(pending)
    }
  }
  return temp
}

export function getRoutes(menuList: MenuItem[]) {
  return flattenDepth(menuList, (item: MenuItem) => item.menuList)
    .filter(item => item.status === '1')
    .sort((left, right) => left.permissionSort - right.permissionSort)
    .map(item => item.urlContent)
}

export function getRoles(roleList: RoleItem[]) {
  return roleList
    .filter(item => item.applicationId === 2)
    .map(item => ({
      ...item,
      roleSign: item.roleSign && item.roleSign.trim(),
    }))
}
