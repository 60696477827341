var render, staticRenderFns
import script from "./CheckFinance.vue?vue&type=script&lang=tsx&"
export * from "./CheckFinance.vue?vue&type=script&lang=tsx&"
import style0 from "./CheckFinance.vue?vue&type=style&index=0&id=2038e6fd&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2038e6fd",
  null
  
)

export default component.exports