import { mutation as mUser } from '@/store/modules/user/types'
import store from '@/store'
import router from '@/router'
import { unsetLocalStore, skipSSO } from '@/utils/auth'

export default function logout() {
  store.commit(`user/${mUser.logged.modify}`, false)
  unsetLocalStore('token')
  const { name, fullPath } = router.currentRoute
  if (name !== 'signed_in') {
    skipSSO(true, fullPath)
  }
}
