export default {
  changeCreateTargetDialogVisible(state: any, visible: boolean) {
    state.createTargetDialogVisible = visible
  },
  changeCreateTaskDialogVisible(state: any, visible: boolean) {
    state.createTaskDialogVisible = visible
  },
  setCourtUser(state: any, list: any[]) {
    state.courtUser = list
  },
  setCourtTaskType(state: any, list: any[]) {
    state.taskTypes = list
  },
}
