import { render, staticRenderFns } from "./ApplyList.vue?vue&type=template&id=1b5f6648&scoped=true&"
import script from "./ApplyList.vue?vue&type=script&lang=ts&"
export * from "./ApplyList.vue?vue&type=script&lang=ts&"
import style0 from "./ApplyList.vue?vue&type=style&index=0&id=1b5f6648&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b5f6648",
  null
  
)

export default component.exports