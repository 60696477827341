import moment from 'moment'
import ApiPath from '@/utils/api-path'
import { getData, postData } from '../request'

// 查询提成列表
export async function getDistributionRuleList(
  params: DistributionRuleListParams,
  pages: CommonPages,
) {
  const { createTimeStart: startTime, createTimeEnd: endTime } = params
  const res = await postData<CommonResult>(ApiPath.distributionRuleList, {
    ...params,
    createTimeStart: startTime && moment(startTime).format('YYYY-MM-DD'),
    createTimeEnd: endTime && moment(endTime).format('YYYY-MM-DD'),
    pageNum: pages.currentPage,
    pageRow: pages.pageSize,
  })
  const list = res.list as unknown[] as DistributionRuleItemData[]
  return { list, total: res.totalCount }
}

export interface DistributionRuleListParams {
  distributionRuleName?: string // 提成名称
  status?: 0 | 1 | null // 状态
  updateUserName?: string // 操作人
  createTimeStart?: Date | null
  createTimeEnd?: Date | null
}

export interface DistributionRuleItemData {
  id: number
  createTime: string // 创建时间
  distributionRuleName: string // 提成名称
  distributionAllRate: number | null // 提成比例
  taxiRate: number | null
  manageRate: number | null
  caseRate: number | null
  cityReward: 0 | 1 // 城市奖金池
  cityRewardRate: number | null // 城市奖金比例
  status: 0 | 1 // 状态
  updateUserName: string // 操作人
  updateTime: string // 更新时间
}

export async function getDistributionData(distributionId: number) {
  const res = await getData<Record<string, unknown>>(
    ApiPath.getDistributionRule(distributionId),
  )
  return {
    ...res,
    cityReward: res.cityReward === 1,
    status: res.status === 1,
  } as unknown as DistributionData
}

interface DistributionData {
  id: number
  distributionRuleName: string
  distributionAllRate: number | null
  taxiRate: number | null
  manageRate: number | null
  caseRate: number | null
  cityReward: boolean
  cityRewardRate: number | null
  status: boolean
}

export interface DistributionFormData {
  id: number | null
  distributionRuleName: string // 提成名称
  distributionAllRate: string // 提成比例
  taxiRate: string // 提成税率
  manageRate: string // 司辅提成比率
  caseRate: string // 司辅案件提成
  cityReward: boolean // 城市奖金池
  cityRewardRate: string // 城市奖金比例
  status: boolean // 状态
}

export function postDistributionData(data: PostDistributionData) {
  const url = data.id
    ? ApiPath.editDistributionRule
    : ApiPath.addDistributionRule
  return postData(url, {
    ...data,
    cityReward: data.cityReward ? 1 : 0,
    status: data.status ? 1 : 0,
  })
}

export interface PostDistributionData {
  id: number | null
  distributionRuleName: string
  distributionAllRate: number
  taxiRate: number
  manageRate: number
  caseRate: number
  cityReward: boolean
  cityRewardRate: number
  status: boolean
}

export function changeDistributionStatus(distributionId: number) {
  return getData(ApiPath.changeDistributionStatus(distributionId))
}

interface CommonPages {
  currentPage: number
  pageSize: number
}

interface CommonResult {
  list: Record<string, unknown>[]
  totalCount: number
}
