import { inject, onMounted, onUnmounted } from '@vue/composition-api'

export const portalKey = Symbol('portal')

export interface Portal {
  target: EventTarget
  focus: () => void
}

export function usePortalParentEnter(onEnter: () => void) {
  const portal = inject<Portal>(portalKey)

  if (portal) {
    let cleanup: () => void

    onMounted(() => {
      portal.target.addEventListener('enter', onEnter)

      cleanup = () => {
        portal.target.removeEventListener('enter', onEnter)
      }
    })

    onUnmounted(() => cleanup?.())
  }

  return {
    focus: () => {
      if (portal) {
        portal.focus()
      }
    },
  }
}
