export interface State {
  [propName: string]: any
}

export const mutation = {
  logged: {
    modify: 'USER_LOGGED_MODIFY',
  },
  info: {
    request: 'USER_INFO_REQUEST',
    success: 'USER_INFO_SUCCESS',
    failure: 'USER_INFO_FAILURE',
  },
  routes: {
    request: 'USER_ROUTES_REQUEST',
    success: 'USER_ROUTES_SUCCESS',
    failure: 'USER_ROUTES_FAILURE',
  },
}

export const action = {
  info: {
    fetch: 'USER_INFO_FETCH',
  },
  routes: {
    fetch: 'USER_ROUTES_FETCH',
  },
}
