import Vue from 'vue'
import { Route, NavigationGuardNext } from 'vue-router'
import store from '@/store'
import { getLocalStore } from '@/utils/auth'
import { mutation as mUser } from '@/store/modules/user/types'

/**
 * 使本地存储的 token 与 状态树中的 logged 保持一致
 */
export default function persist(
  _: Route,
  __: Route,
  next: NavigationGuardNext<Vue>,
) {
  const logged = !!getLocalStore('token')
  store.commit(`user/${mUser.logged.modify}`, logged)
  next()
}
