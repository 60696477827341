import { render, staticRenderFns } from "./ConsultList.vue?vue&type=template&id=6b764230&scoped=true&"
import script from "./ConsultList.vue?vue&type=script&lang=ts&"
export * from "./ConsultList.vue?vue&type=script&lang=ts&"
import style0 from "./ConsultList.vue?vue&type=style&index=0&id=6b764230&lang=less&scoped=true&"
import style1 from "./ConsultList.vue?vue&type=style&index=1&module=true&lang=less&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "6b764230",
  null
  
)

export default component.exports