export interface State {
  [propName: string]: any
}

export const mutation = {
  condition: {
    modify: 'CONDITION_MODIFY',
    reset: 'CONDITION_RESET',
  },
  pagination: {
    modify: 'PAGINATION_MODIFY',
  },
  dataList: {
    request: 'DATA_LIST_REQUEST',
    success: 'DATA_LIST_SUCCESS',
    failure: 'DATA_LIST_FAILURE',
  },
  dataTotal: {
    request: 'DATA_TOTAL_REQUEST',
    success: 'DATA_TOTAL_SUCCESS',
    failure: 'DATA_TOTAL_FAILURE',
  },
  money: {
    modify: 'MONEY_MODIFY',
  },
}

export const action = {
  condition: {
    modify: 'CONDITION_MODIFY',
  },
  pagination: {
    modify: 'PAGINATION_MODIFY',
  },
  dataList: {
    fetch: 'DATA_LIST_FETCH',
  },
}
