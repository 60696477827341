// 将文本中的数字格式化成定点千分位形式
export default function (
  value: string | number | null | undefined,
  defaultValue = '-',
  digits = 2,
) {
  if (value === '' || value === null || value === undefined) {
    return defaultValue
  }
  return (typeof value === 'number' ? value.toString() : value).replace(
    /(?:(\d+)\.?|\.)(\d*)?/g,
    ($0, $1 = '0', $2 = '0') => {
      let integer = parseInt($1, 10)
      let decimal =
        parseInt($2.slice(0, digits + 1).padEnd(digits + 1, '0'), 10) + 5
      const carry = 10 ** (digits + 1)
      if (decimal >= carry) {
        integer += 1
        decimal -= carry
      }
      return `${integer
        .toString()
        .replace(/(?!^)(?=(\d{3})+$)/g, ',')}.${decimal
        .toString()
        .slice(0, -1)
        .padStart(digits, '0')}`
    },
  )
}
