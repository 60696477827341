import { Commit } from 'vuex'
import { eMsg } from '@/utils/feedback'
import {
  getTaskInfo,
  updateTaskInfo,
  saveTask,
  queryTaskDetails,
  checkTask,
  queryTaskType,
} from '@/services/task'
import SampleService from '@/services/sample'
import { taskDialogEvent } from '@/services/task/task-event'
import moment from 'moment'

export default {
  async queryTaskInfo(context: { commit: Commit }, id: string) {
    try {
      const taskInfo = await getTaskInfo(id)
      return taskInfo
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
  async queryTaskDetails(context: { commit: Commit }, id: string) {
    try {
      return await queryTaskDetails(id)
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
  async updateTask(context: { commit: Commit }, param: any) {
    try {
      await updateTaskInfo(param)
      return 'ok'
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
  async submitTaskAfterUpdate(context: { commit: Commit }, param: any) {
    try {
      await updateTaskInfo(param)
      await saveTask({
        id: param.id,
      })
      return 'ok'
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
  async checkTask(context: { commit: Commit }, id: string) {
    try {
      await checkTask(id)
      return 'ok'
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
  async queryTaskType(context: { commit: Commit }, courtId: string) {
    try {
      return await queryTaskType(courtId)
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
  async openAuctionRelatedDialog(context: { commit: Commit }, param: any) {
    try {
      const { taskId, targetId, auctionId, moduleName, finishTime } = param
      context.commit('setModuleName', moduleName)
      const { promise } = SampleService.getRelatedViewNum({
        taskId,
        targetId,
        auctionId,
      })
      const num = await promise
      context.commit('setViewNum', num)
      if (num > 0) {
        context.commit('setTaskCheckDialogVisible', true)
        if (
          moduleName === '任务编辑' ||
          moduleName === '任务删除' ||
          finishTime
        ) {
          const expirationStatus = moment(finishTime).isBefore(new Date())
          context.commit('setSendMsg', !expirationStatus)
          context.commit('setSendMsgDisabled', expirationStatus)
        } else {
          context.commit('setSendMsg', true)
          context.commit('setSendMsgDisabled', false)
        }
        return await taskDialogEvent.onConfirm()
      } else {
        context.commit('setSendMsg', false)
        context.commit('setSendMsgDisabled', true)
      }
      return 'ok'
    } catch (errMsg) {
      return eMsg(errMsg as string)
    }
  },
}
