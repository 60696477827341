import { Message } from 'element-ui'
import { ActionTree } from 'vuex'
import { getUserInfo, getUserPermission } from '@/services/user'
import { State, mutation as mUser, action as aUser } from './types'

const actionTree: ActionTree<State, State> = {
  [aUser.info.fetch]({ commit, state }) {
    const source = new AbortController()
    if (state.info.source !== null) {
      state.info.source.abort()
    }
    commit(mUser.info.request, { source })
    return getUserInfo(source.signal).then(
      value => {
        if (state.info.source === source) {
          commit(mUser.info.success, { data: value })
        }
        return value
      },
      reason => {
        if (state.info.source === source) {
          commit(mUser.info.failure, { error: reason })
        }
        Message.error(reason.message)
        throw reason
      },
    )
  },
  [aUser.routes.fetch]({ commit, state }) {
    const source = new AbortController()
    if (state.routes.source !== null) {
      state.routes.source.abort()
    }
    commit(mUser.routes.request, { source })
    return getUserPermission(source.signal).then(
      value => {
        if (state.routes.source === source) {
          commit(mUser.routes.success, { data: value })
        }
        return value
      },
      reason => {
        if (state.routes.source === source) {
          commit(mUser.routes.failure, { error: reason })
        }
        Message.error(reason.message)
        throw reason
      },
    )
  },
}

export default actionTree
