import { Message } from 'element-ui'
import axios from 'axios'
import { getLocalStore } from '@/utils/auth'
import logout from '@/services/logout'

axios.interceptors.request.use(config => {
  const headers = config.headers || {}
  const token = getLocalStore('token')
  return {
    ...config,
    headers: {
      token,
      ...headers,
    },
    withCredentials: true,
  }
})

axios.interceptors.response.use(response => {
  const { returnCode, returnMsg } = response.data
  if (returnCode === '100001') {
    Message.error(returnMsg)
    logout()
  }
  return response
})
