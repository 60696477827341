import { post } from '@/utils/request'
import request, { postData } from '../request'
import ApiPath from '@/utils/api-path'

export function getCourt(userId?: string) {
  const data: any = {
    pageNum: 1,
    pageRow: 99999,
    type: 2,
  }
  if (userId) {
    data.userId = userId
  }
  return post(ApiPath.getCourtList, data)
}

export function getTeamBD() {
  return post(ApiPath.getTeamBD, {})
}

export function getJudge(courtId: string) {
  return post(ApiPath.getJudgeList, { courtId })
}

export function createCase(params: any) {
  return postData(ApiPath.createCase, params)
}

export function updateCase(caseData: any) {
  return post(ApiPath.updateCase, caseData)
}
export function getCase(id: string) {
  return post(ApiPath.caseDetail, { id })
}

export function createJudge(judgeData: any) {
  return post(ApiPath.createJudge, judgeData)
}

// 删除规则
export function deleteCase(id: string) {
  return post(ApiPath.deleteCase, { id })
}

export function getMyCaseList(userId = '') {
  return post(ApiPath.getCaseList, {
    dataRange: 1,
    pageNum: 1,
    pageRow: 9999999,
    userId,
  })
}

export function selectFreeByCourtId(courtId: number) {
  return postData(ApiPath.selectFreeByCourtId(courtId))
}

export function getInvoiceInfo(targetId: string | number) {
  return request(ApiPath.getInvoiceById(targetId))
}

export function getCrossAreaType(courtId: number, regionId: string) {
  return request<CrossAreaType>(ApiPath.getCrossAreaType(courtId, regionId))
}

type CrossAreaType =
  | 'UNKNOWN' // 未知
  | 'UN_CROSS' // 非异地
  | 'CROSS_PROVINCE' // 跨省
  | 'CROSS_CITY' // 跨市
