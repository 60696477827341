var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white"},[_vm._m(0),_c('div',{staticClass:"p-t-b-25"},[_c('tasks-list-filter',{ref:"tastsListFilter",on:{"filterChange":_vm.handleFilterChange}})],1),_c('div',{staticClass:"list-box"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.listLoding),expression:"listLoding"}],attrs:{"data":_vm.taskListData,"stripe":"","border":""}},[_c('el-table-column',{attrs:{"prop":"tableIndex","label":"序号","width":"56","align":"center"}}),_c('el-table-column',{attrs:{"prop":"createTime","label":"创建时间","width":"112","align":"center","class-name":"column-time"}}),_c('el-table-column',{attrs:{"prop":"courtName","label":"法院名称","align":"center"}}),_c('el-table-column',{attrs:{"prop":"judgeName","label":"承办法官","align":"center"}}),_c('el-table-column',{attrs:{"prop":"caseNum","label":"执行案号","align":"center"}}),_c('el-table-column',{attrs:{"prop":"taskType","label":"任务类型","align":"center"}}),_c('el-table-column',{attrs:{"prop":"targetName","label":"标的物名称","align":"center"}}),_c('el-table-column',{attrs:{"prop":"executive","label":"执行人","align":"center"}}),_c('el-table-column',{attrs:{"prop":"finishTime","label":"希望完成时间","width":"112","align":"center","class-name":"column-time"}}),_c('el-table-column',{attrs:{"prop":"taskClassifyDesc","label":"任务分类","align":"center"}}),_c('el-table-column',{attrs:{"prop":"status","label":"任务状态","align":"center"}}),_c('el-table-column',{attrs:{"prop":"finishTime","label":"实际完成时间","width":"112","align":"center","class-name":"column-time"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.actualTime && row.statusType == '3')?_c('span',[_vm._v(" "+_vm._s(row.actualTime)+" ")]):_c('span',[_vm._v("-")])]}}])}),_c('el-table-column',{attrs:{"prop":"operation","label":"操作","width":"144","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleView(row)}}},[_vm._v(" 查看 ")]),(
            _vm.isCrossAdmin &&
            (row.taskClassify === 'CROSS_PROVINCE' ||
              row.taskClassify === 'CROSS_CITY')
          )?[(row.statusType == '1')?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.toDeleteTask(row)}}},[_vm._v(" 删除 ")]):_vm._e(),(row.statusType == '3')?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.toReturnTask(row)}}},[_vm._v(" 退回 ")]):_vm._e()]:_vm._e()]}}])})],1),_c('server-pagination',{ref:"pagination",attrs:{"url":_vm.TasksListUrl},on:{"listChange":_vm.handleListChange,"loadingChange":_vm.handleLoadingChange}})],1),_c('ViewDetail',{ref:"viewDetailRef"}),(_vm.updataTaskDialogVisible)?_c('el-dialog',{attrs:{"title":_vm.dialogTitle,"visible":_vm.updataTaskDialogVisible,"width":"750px","close-on-click-modal":false},on:{"update:visible":function($event){_vm.updataTaskDialogVisible=$event}}},[(_vm.dialogType === '4')?_c('return-task',{attrs:{"task-info":_vm.taskInfo},on:{"saveSuccess":_vm.saveSuccessful,"cancelDio":_vm.cancelDio}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-title"},[_c('div',[_vm._v("部门任务")])])}]

export { render, staticRenderFns }