import 'normalize.css'

import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import '@/assets/default.scss'
import '@/assets/icon-font/iconfont.css'

import VideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import { Promised } from 'vue-promised'

import './plugins/axios-extend'
import './plugins/echarts-lib'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.use(VueCompositionAPI)
Vue.use(ElementUI)
Vue.use(VideoPlayer)

Vue.component('PsvPromised', Promised)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
