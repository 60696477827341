import { render, staticRenderFns } from "./TaskUpdate.vue?vue&type=template&id=7f6b706f&scoped=true&"
import script from "./TaskUpdate.vue?vue&type=script&lang=ts&"
export * from "./TaskUpdate.vue?vue&type=script&lang=ts&"
import style0 from "./TaskUpdate.vue?vue&type=style&index=0&id=7f6b706f&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f6b706f",
  null
  
)

export default component.exports