import {
  SourcePayload,
  DataPayload,
  ErrorPayload,
  toRequest,
  toSuccess,
  toFailure,
} from '@/utils/store-types'
import { State, mutation as mInvoice } from './types'
import { MutationTree } from 'vuex'

const mutationTree: MutationTree<State> = {
  // 重置条件
  [mInvoice.condition.reset](state, payload = {}) {
    state.condition = payload
  },
  // 搜索条件
  [mInvoice.condition.modify](state, payload) {
    state.condition = { ...state.condition, ...payload }
  },
  // 修改分页
  [mInvoice.pagination.modify]({ pagination }, { pageSize, currentPage }) {
    if (pageSize !== undefined) {
      pagination.pageSize = pageSize
    }
    if (currentPage !== undefined) {
      pagination.currentPage = currentPage
    }
  },
  // 数据列表
  [mInvoice.dataList.request](state, { source }: SourcePayload) {
    toRequest(state.dataList, source)
  },
  [mInvoice.dataList.success](state, { data }: DataPayload) {
    toSuccess(state.dataList, data)
  },
  [mInvoice.dataList.failure](state, { error }: ErrorPayload) {
    toFailure(state.dataList, error)
  },
  // 数据总数
  [mInvoice.dataTotal.request](state, { source }: SourcePayload) {
    toRequest(state.dataTotal, source)
  },
  [mInvoice.dataTotal.success](state, { data }: DataPayload) {
    toSuccess(state.dataTotal, data)
  },
  [mInvoice.dataTotal.failure](state, { error }: ErrorPayload) {
    toFailure(state.dataTotal, error)
  },
  // 页面金额汇总
  [mInvoice.money.modify](state, payload) {
    state.money = payload
  },
}

export default mutationTree
