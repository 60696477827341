export interface OtherFormObj {
  propertyAndCaseHandleInfo: string
  receiptNo: string
}

export type DefinitionTypeString = 'NATURAL_PERSON' | 'COMPANY'

export interface ExecutedPersonFormObj {
  id?: string
  key?: string
  definitionType?: DefinitionTypeString
  name?: string
  identityNo?: string
}

export function getRandomStr(): string {
  return Math.random().toString(36).substr(2)
}

export function getNewEmptyExecutedPersonObj(): ExecutedPersonFormObj {
  return {
    key: getRandomStr(),
    definitionType: 'NATURAL_PERSON',
  }
}

export interface FundsSummaryFormObj {
  auctionAmount?: number
  sellOffAmount?: number
  deductsAmount?: number | null
  drawAmount?: number | null
  otherAmount?: number
}

export interface ExecuteFeeFormObj {
  performFee?: number | string | null
  evaluationFee?: number | null
  auctionAssistFee?: number | null
  unlockFee?: number | null
  keeperFee?: number | null
  otherFee?: number | null
}

export interface PayPriorityFormObj {
  salaryAmount?: number | null
  projectFundsAmount?: number | null
  pledgeAmount?: number | null
  otherPriorityAmount?: number | null
}

export function getNewEmptyClaimFormObj(defendantName: string): ClaimFormObj {
  return {
    key: getRandomStr(),
    applierName: '',
    defendantName,
    caseNo: '',
    caseStatus: '',
    remark: '',
    principalAmount: null,
    interestAmount: null,
    interestForDelayAmount: null,
    lawsuitFee: null,
    unLockOrOtherFee: null,
  }
}

export interface ClaimFormObj {
  key: string
  applierName: string
  defendantName: string
  caseNo: string
  caseStatus: string
  remark: string

  principalAmount: number | string | null
  interestAmount: number | string | null
  interestForDelayAmount: number | string | null
  lawsuitFee: number | string | null

  unLockOrOtherFee: number | string | null
}
