import { API_URL } from '@/utils/api-path'
import { getLocalStore } from '@/utils/auth'
import { HTTPError, ApiError, ApiResult } from './errors'
import logout from './logout'

async function grab(input: RequestInfo, init?: RequestInit) {
  const headers = new Headers((init && init.headers) || {})
  const token = getLocalStore('token')
  if (token) {
    headers.set('token', token)
  }
  if (typeof (init && init.body) === 'string' && !headers.has('content-type')) {
    headers.set('content-type', 'application/json')
  }
  const response = await fetch(input, { ...init, headers })
  return checkStatus(response)
}

function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  }
  throw new HTTPError(response)
}

export async function baseRequest<T = unknown>(
  input: RequestInfo,
  init?: RequestInit,
) {
  const response = await grab(input, init)
  const result = await response.json()
  if (result.returnCode === '100001') {
    logout()
  }
  return result as T
}

export default async function request<T = unknown>(
  input: RequestInfo,
  params?: RequestInit & { skipLogin?: boolean },
) {
  const { skipLogin, ...init } = params || {}
  const response = await grab(input, init)
  const result: ApiResult<T> = await response.json()
  if (result.returnCode === '100') {
    return result.returnData
  }
  if (!skipLogin && result.returnCode === '100001') {
    logout()
  }
  throw new ApiError(result)
}

export function getData<T = unknown>(url: string) {
  url = handleUrl(url)

  return request<T>(url)
}

type PlainData = object | unknown[]
export function postData<T = unknown>(url: string, data?: PlainData) {
  url = handleUrl(url)
  return request<T>(url, {
    body: JSON.stringify(data),
    method: 'POST',
  })
}

export function putData<T = unknown>(url: string, data?: PlainData) {
  url = handleUrl(url)
  return request<T>(url, {
    body: JSON.stringify(data),
    method: 'PUT',
  })
}

function handleUrl(url: string) {
  if (/^https?/.test(url)) {
    return url
  }
  return API_URL + url.replace(/^\//, '')
}
