import moment from 'moment'

/**
 * 获取本周周一和周日日期
 */
export function getCurrentWeek() {
  const start = moment().weekday(1).format('YYYY-MM-DD') //本周一
  const end = moment().weekday(7).format('YYYY-MM-DD') //本周日
  return [start, end]
}

/**
 * 获取前 i 周的周一和周日日期，并以数组的方式返回
 * 当 i=1，获取 上周一和上周日的日期
 * 当 i=2，获取 上上周一和上上周日的日期
 * ...以此类推
 * @param i
 * @param offset 偏移天数 如取1时 为周日到周六的日期
 */
export function getLastWeek(i = 0, offset = 0) {
  let weekOfDay = parseInt(moment().format('E')) //计算今天是这周第几天
  weekOfDay = weekOfDay + offset
  const lastMonday = moment()
    .subtract(weekOfDay + 7 * i - 1, 'days')
    .format('YYYY-MM-DD') //周一日期
  const lastSunday = moment()
    .subtract(weekOfDay + 7 * (i - 1), 'days')
    .format('YYYY-MM-DD') //周日日期
  return [lastMonday, lastSunday]
}

/**
 * 获取近几周的 报表周
 * 以上周五到本周四为一个报表周
 * @param num 近几周
 */
//
export function getWeekList(num = 1) {
  const arr = []
  let start = 0
  // 如果今日是周四以后 获取下周为第一个报表周
  if (parseInt(moment().format('E')) > 4) {
    start = -1
    num = num - 1
  }
  for (let i = start; i < num; i++) {
    const week = getLastWeek(i, 3)
    arr.push({
      label: `${moment(week[0]).format('MM月DD日')}至${moment(week[1]).format(
        'MM月DD日',
      )}`,
      value: `${moment(week[0]).format('YYYY-MM-DD')}至${moment(week[1]).format(
        'YYYY-MM-DD',
      )}`,
    })
  }
  return arr
}

// 获取2019年到今年
export function getSoFarYears() {
  const end = new Date().getFullYear()
  return Array.from({ length: end - 2019 + 1 }, (_, index) => {
    const year = end - index + ''
    return { label: year + '年', value: year }
  })
}

// 获取过去月份列表
export function getLastMonth(num = 1) {
  const arr = []
  for (let i = 0; i < num; i++) {
    const month = moment().subtract(i, 'month').format('YYYY-MM')
    arr.unshift(month)
  }
  return arr
}

// 获取当月日期
export function getCurrentMonthDay() {
  // const num = moment().get('date')
  const num = moment().daysInMonth()
  const arr = []
  for (let i = 0; i < num; i++) {
    const day = moment().endOf('month').subtract(i, 'day').format('YYYY-MM-DD')
    arr.unshift(day)
  }
  return arr
}
