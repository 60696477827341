export class HTTPError extends Error {
  constructor(public response: Response) {
    // Set the message to the status text, such as Unauthorized,
    // with some fallbacks. This message should never be undefined.
    super(
      response.statusText ||
        String(
          response.status === 0 || response.status
            ? response.status
            : 'Unknown response error',
        ),
    )
    this.name = 'HTTPError'
  }
}

export class ApiError<T = unknown> extends Error {
  constructor(public reason: ApiResult<T>) {
    super(reason.returnMsg)
    this.name = 'ApiError'
  }
}

export interface ApiResult<T> {
  returnCode: string
  returnData: T
  returnMsg: string
}
