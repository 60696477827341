export function getRegionIds(code?: string | number | null) {
  const codes: any = /(\d{0,2})(\d{0,2})(\d*)/.exec('' + code)
  const data: string[] = [codes[1], codes[2], codes[3]]
  const list: number[] = []
  if (/[^0]/.test(data[0])) {
    list[0] = parseInt(data[0]) * 10000
    if (/[^0]/.test(data[1])) {
      list[1] = parseInt(data[0] + data[1]) * 100
      if (/[^0]/.test(data[2])) {
        list[2] = parseInt(data[0] + data[1] + data[2])
      }
    }
  }
  return list
}

export function getRegionCodeList(code?: string | number | null) {
  const codes = /(\d{0,2})(\d{0,2})(\d*)/.exec('' + code) as string[]
  const data = [codes[1], codes[2], codes[3]]
  const list: string[] = []
  if (/[^0]/.test(data[0])) {
    let prefix = data[0]
    list[0] = prefix + '0000'
    if (/[^0]/.test(data[1])) {
      prefix += data[1]
      list[1] = prefix + '00'
      if (/[^0]/.test(data[2])) {
        list[2] = prefix + data[2]
      }
    }
  }
  return list
}

export function getRegionCode(ids: number[]) {
  let lastIndex = ids.length - 1
  while (lastIndex >= 0 && !ids[lastIndex]) lastIndex--
  return ids[lastIndex] || null
}
