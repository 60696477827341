import ApiPath from '@/utils/api-path'
import { postData } from '../request'

// 创建规则名获取规则id
export function addRule(param: any) {
  return postData(ApiPath.addRule, param)
}
// 复制规则
export function copyRule(param: any) {
  return postData(ApiPath.copyRule, param)
}

// 编辑规则
export function editRule(param: any) {
  return postData(ApiPath.editRule, param)
}

// 删除规则
export function deleteRule(id: string) {
  return postData(ApiPath.deleteRule, { id })
}

// 删除规则详情里的分条
export function deleteRuleDetail(id: string) {
  return postData(ApiPath.deleteRuleDetail, { id })
}

// 查询规则绑定的法院列表
export function queryCourtList(ruleId: string) {
  return postData(ApiPath.courtList, { ruleId })
}

// 根据id查询该id的所有规则详情列表
export function getRuleDetailList(id: number | string) {
  return postData(ApiPath.ruleDetailList, { id })
}

export function editRuleDetail(params: Record<string, unknown>) {
  return postData(ApiPath.editRuleDetail, params)
}

export function addRuleDetail(params: Record<string, unknown>) {
  return postData(ApiPath.addRuleDetail, params)
}
