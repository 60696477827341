export interface ParamsBase {
  targetId: string | number
  invoiceId: string | number
  type: 1 | 2 | 3 | 4 // 1: house|房产 2: car|机动车 3: ship|其他运输设备 4: stock|其他财产
  courtName: string
  caseNum: string
  targetName: string
  endTime: string
  reservePrice: number
  billing: number
  blueBilling: number
  serviceMoney: number
  countFlag: number
  chargingMethod: number
  rate: string
}

export interface FormValue {
  location: string // 标的所在地区
  // 0: 未申请
  // 1: 申请中, 2: 已通过, 3: 申请驳回
  // 4: 已开票, 5: 已到账, 6: 已归档
  // 7: 申请作废, 8: 作废驳回, 9: 已作废
  status: number
  serviceType: number // 1: 收费, 2: 不收费
  chargingMethod: number // 0: 比率, 1:案件
  rate: string // 收费比率
  serviceMoney: string // 服务费
  id: string | null // id
  parkingFee: number // 是否收取停车费 1: 收费, 0: 不收费
  advancePayment: number //是否垫付   0:否 1：是
  advancePaymentAmount: string //垫付金额
  dayMoney: string // 停车费 元/日
  dayNumber: string // 停车日
  billing: string // 开票金额
  realBilling: string // 开票金额
  createTime: string // 申请开票时间
  invoiceInputType: InvoiceInputType // 发票录入方式
  presetInvoiceId: string | null // 预设发票id
  titleType: 1 | 2 // 抬头类型 1: 企业, 2: 个人/非企业
  invoiceTitle: string // 发票抬头
  invoiceNum: string // 发票税号
  invoiceType: ApplyInvoiceType // 发票类型
  reservePrice: string
  phone: string // 电话号码
  unitAddress: string // 单位地址
  depositBank: string // 开户银行
  bankAccount: string // 银行账号
  applyRemark: string // 申请备注
  invoiceDelayReason: string // 回款超期说明
  archiveRemark: string // 归档备注
  backReason: number | null // 驳回原因 1: 金额错误, 2: 坏账处理, 3: 发票形式错误, 4: 其他原因
  reviewRemark: string // 审核备注
  invalidApplyRemark: string // 作废申请备注
  invalidRemark: string // 作废审核备注
  // amount: string; // 到账金额
  // entryTime: string; // 到账时间
  makeOutRemark: string // 开票备注
  invoiceNo: string // 发票号码
  invoiceTime: string // 开票时间
  accountRemark: string // 到账备注
  invoiceEntrys: Array<{ amount: string; entryTime: string }> // 到账时间
  ruleName: string | null // 规则名称
  ruleDetail: string | null // 规则详细
  interval: number | null // 收费规则过期天数
  countFlag: number | null // 1: 主动填写, 2: 被动填写
  redInvoice: boolean // 是否红票
}

// 发票录入方式
export type InvoiceInputType = 'COURT_PRESET' | 'CONSUMER_INPUT'

// 发票类型
export type ApplyInvoiceType =
  | 2 // 增值税普通纸质票
  | 3 // 增值税普通电子票
  | 1 // 增值税专用纸质票

type PartialEx<T, K> = { [P in keyof T]?: K }

interface Validator {
  type:
    | 'string'
    | 'number'
    | 'boolean'
    | 'method'
    | 'regexp'
    | 'integer'
    | 'float'
    | 'array'
    | 'object'
    | 'enum'
    | 'date'
    | 'url'
    | 'hex'
    | 'email'
  required: boolean
  trigger: 'change' | 'blur'
  pattern: RegExp
  range: { min: number; max: number }
  enum: any[]
  fields?: { [key: string]: Validator }
  message: string | JSX.Element
  validator: (
    rule: Validator[],
    value: any,
    callback: (err?: Error) => void,
  ) => void
}

export type Rules = PartialEx<FormValue, Array<Partial<Validator>>>

export function getEmptyFormValue(): FormValue {
  return {
    location: '',
    id: null,
    status: -1,
    serviceType: -1,
    chargingMethod: 1,
    rate: '',
    serviceMoney: '',
    parkingFee: 1,
    advancePayment: 1,
    advancePaymentAmount: '',
    dayMoney: '',
    dayNumber: '',
    billing: '',
    realBilling: '',
    createTime: '',
    invoiceInputType: 'COURT_PRESET',
    presetInvoiceId: null,
    titleType: 1,
    invoiceTitle: '',
    invoiceNum: '',
    reservePrice: '',
    invoiceType: 2,
    phone: '',
    unitAddress: '',
    depositBank: '',
    bankAccount: '',
    applyRemark: '',
    invoiceDelayReason: '',
    archiveRemark: '',
    backReason: null,
    reviewRemark: '',
    invalidApplyRemark: '',
    invalidRemark: '',
    makeOutRemark: '',
    accountRemark: '',
    invoiceNo: '',
    invoiceTime: '',
    ruleName: null,
    ruleDetail: null,
    interval: null,
    countFlag: null,
    invoiceEntrys: [],
    redInvoice: false,
  }
}

export function getFinite(n: number, defaultValue = 0) {
  return Number.isFinite(n) ? n : defaultValue
}
