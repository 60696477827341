import { Method } from 'axios'
import request, { baseRequest } from '@/services/request'

/**
 * @declare 此方法局限性太强
 */
export function get(url: string) {
  return http(url)
}

/**
 * @declare 此方法局限性太强
 */
export function post(url: string, data: any) {
  return http(url, data, 'post')
}

/**
 * @declare 此方法局限性太强
 */
function http<T = any>(
  url: string,
  data: { [key: string]: any } = {},
  method: Method = 'get',
) {
  const init: RequestInit = { method }
  if (method !== 'GET' && method !== 'get') {
    init.body = JSON.stringify(data)
  }
  return request<T>(url, init).catch(err => {
    throw err.message
  })
}

interface OtherApiResult<T = any> {
  code: string
  data: T
  msg: string
}

/**
 * @declare 此方法局限性太强
 */
export function otherHttp<T = any>(
  url: string,
  data: { [key: string]: any } = {},
  method: Method = 'get',
) {
  const init: RequestInit = { method }
  if (method !== 'GET' && method !== 'get') {
    init.body = JSON.stringify(data)
  }
  return baseRequest<OtherApiResult<T>>(url, init)
    .then(result => {
      if (result.code !== '0') {
        throw new Error(result.msg)
      }
      return result.data
    })
    .catch(err => {
      throw err.message
    })
}
