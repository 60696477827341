import { NavigationGuard, RouteConfig } from 'vue-router'

type GuardItem<V extends Vue = Vue> =
  | false
  | NavigationGuard<V>
  | null
  | undefined

export function mergePath(prefix: string, path: string) {
  if (/^\//.test(path) || prefix === '') {
    return path
  }
  if (prefix === '/') {
    return `/${path}`
  }
  return `${prefix.replace(/([^/])$/, '$1/')}${path}`
}

export function composeGuard<V extends Vue = Vue>(...guards: GuardItem<V>[]) {
  const guard = guards.reduce((a: GuardItem<V>, b: GuardItem<V>) => {
    if (!a) {
      return b
    }
    if (!b) {
      return a
    }
    return (to, from, next) => {
      a(to, from, raw => {
        if (raw === undefined) {
          b(to, from, next)
        } else {
          next(raw)
        }
      })
    }
  }, null)
  return guard || null
}

export function withPrefix(
  prefix: string,
  routes: RouteConfig[],
  beforeEnters?: GuardItem[],
) {
  return routes.map(route => {
    route.path = mergePath(prefix, route.path)
    if (beforeEnters && beforeEnters.length > 0) {
      route.beforeEnter =
        composeGuard(route.beforeEnter, ...beforeEnters) || undefined
    }
    return route
  })
}
