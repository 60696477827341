import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state: {
    taskCheckDialogVisible: false,
    sendMsg: false,
    sendMsgDisabled: false,
    viewNum: 0,
    moduleName: '',
    moduleDes: '',
  },
  mutations,
  actions,
}
