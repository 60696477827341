import { MapCache } from 'lodash'
import { isPromise } from './utils'

class PromiseMapCache<K = any, V = any> implements MapCache {
  constructor(private cache: MapCache) {}

  delete(key: K) {
    return this.cache.delete(key)
  }

  get(key: K): V {
    return this.cache.get(key)
  }

  has(key: K) {
    return this.cache.has(key)
  }

  set(key: K, value: V) {
    if (isPromise(value)) {
      value.catch(() => this.cache.delete(key))
    }
    this.cache.set(key, value)
    return this
  }

  clear() {
    this.cache.clear?.()
  }
}

export default PromiseMapCache
