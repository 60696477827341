import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state: {
    selectId: '', // 下拉选中项
    courtList: [], // 权限内法院列表
    regionList: [], // 权限内省份列表
    targetTradingLoading: false,
    targetTradingList: [
      { time: '昨日' },
      { time: '本周' },
      { time: '本月' },
      { time: '全年' },
    ],
    incomeListLoading: false,
    incomeList: [],
    // 地图当前是否选中省
    isSelectProvince: false,
    // 过去半年回款
    lastSixMonthList: [],
    // 当月服务标的趋势
    serviceAuctionList: [],
    // 各平台数据对比 上拍数据列表
    targetNumList: [],
    // 成交数据列表
    turnoverTargetNumList: [],
  },
  mutations,
  actions,
}
